import React, { ReactElement } from "react";

import s from "./Footer.module.scss";
import { isOpenNewTab } from "./helpers";
import IconFacebook from "./IconFacebook";
import IconLinkedIn from "./IconLinkedIn";
import IconTwitter from "./IconTwitter";
import LogoInkr from "./LogoInkr";


export const FOOTER_LINKS = {
  workWithUs: {
    localize: "https://inkr.com/localize",
    distribute: "mailto:localize@inkr.com",
    translators: "https://inkr.breezy.hr",
    publishers: "https://inkr.com/publishers",
  },

  forReaders: {
    explore: "https://inkr.com",
    getBeta: "https://beta.inkr.com",
    getIOS: "https://smarturl.it/inkrios",
    getAndroid: "https://smarturl.it/inkrandroid",
  },

  company: {
    inkrHome: "https://inkr.com/about",
    inquiries: "https://inkr.com/about#form-business-about",
    careers: "https://inkr.breezy.hr",
    blog: "https://medium.com/inkrcomics",
  },

  resources: {
    support: "https://help.inkr.com",
    privacy: "https://inkr.com/privacy",
    terms: "https://inkr.com/terms",
    sitemap: "https://inkr.com/sitemap",
  },

  home: "/",
  facebook: "https://www.facebook.com/inkrcomics",
  twitter: "https://twitter.com/inkr",
  linkedin: "https://www.linkedin.com/company/inkr-global",
};

const getLinkList = [
  [
    {
      label: "INKR COMICS",
    },
    {
      label: "Explore",
      href: FOOTER_LINKS.forReaders.explore,
    },
    {
      label: "Get IOS app",
      href: FOOTER_LINKS.forReaders.getIOS,
      targetBlank: true,
    },
    {
      label: "Get Android app",
      href: FOOTER_LINKS.forReaders.getAndroid,
      targetBlank: true,
    },
  ],
  [
    {
      label: "WORK WITH US",
    },
    {
      label: "Publishers",
      href: FOOTER_LINKS.workWithUs.publishers,
    },
    {
      label: "Translators",
      href: FOOTER_LINKS.workWithUs.translators,
      targetBlank: false,
    },
    {
      label: "Localize",
      href: FOOTER_LINKS.workWithUs.localize,
    },
  ],
  [
    {
      label: "COMPANY",
    },
    {
      label: "About us",
      href: FOOTER_LINKS.company.inkrHome,
    },
    {
      label: "Inquiries",
      href: FOOTER_LINKS.company.inquiries,
    },
    {
      label: "Careers",
      href: FOOTER_LINKS.company.careers,
    },
    {
      label: "Blog",
      href: FOOTER_LINKS.company.blog,
    },
  ],
  [
    {
      label: "RESOURCES",
    },
    {
      label: "Support",
      href: FOOTER_LINKS.resources.support,
    },
    {
      label: "Privacy",
      href: FOOTER_LINKS.resources.privacy,
    },
    {
      label: "Terms",
      href: FOOTER_LINKS.resources.terms,
    },
    {
      label: "Sitemap",
      href: FOOTER_LINKS.resources.sitemap,
    },
  ],
];

interface CustomLinkProps {
  children?: ReactElement;
  href?: string;
}

const CustomLink = ({ href, children }: CustomLinkProps) => {
  const openNewTab = isOpenNewTab(href || "");

  if (openNewTab) {
    return children || null;
  }

  if (href && href.startsWith("mailto:")) {
    return children || null;
  }

  return <a href={href || ""}>{children}</a>;
};

interface Link {
  label: string;
  href?: string;
  targetBlank?: boolean;
}

const Footer = React.memo(() => {
  const renderLink = ({ href = "", label, targetBlank = true }: Link, idx: number) => {
    if (!href) {
      return (
        <li key={`linkItem-${idx}`} className={s.linkItem}>
          <span className={s.linkLabel}>{label}</span>
        </li>
      );
    }

    const openNewTab = isOpenNewTab(href);

    return (
      <li key={`linkItem-${idx}`} className={s.linkItem}>
        <CustomLink href={href}>
          <a
            target={openNewTab && targetBlank ? "_blank" : undefined}
            rel={openNewTab ? "noopener" : undefined}
            href={href}
            className={s.link}
          >
            {label}
          </a>
        </CustomLink>
      </li>
    );
  };

  const renderLogo = (
    <div className={s.logoInkr}>
      <a className={s.logoFooter} href={FOOTER_LINKS.home}>
        <LogoInkr />
      </a>
    </div>
  );

  return (
    <>
      <div className={s.container}>
        <div className={s.empty} />
        <div className={s.inner}>
          <div className={s.contentArea}>
            {/* LOGO AREA */}
            <div className={s.logoArea}>
              {renderLogo}

              <div>
                <div>
                  <a href={FOOTER_LINKS.facebook} target="_blank" rel="noopener noreferrer" className={s.social}>
                    <div className={s.socialIcon}>
                      <IconFacebook />
                    </div>
                    <div className={s.socialText}>Facebook</div>
                  </a>
                </div>
                <div>
                  <a href={FOOTER_LINKS.twitter} target="_blank" className={s.social} rel="noopener noreferrer">
                    <div className={s.socialIcon}>
                      <IconTwitter />
                    </div>
                    <div className={s.socialText}>Twitter</div>
                  </a>
                </div>
                <div>
                  <a href={FOOTER_LINKS.linkedin} target="_blank" className={s.social} rel="noopener noreferrer">
                    <div className={s.socialIcon}>
                      <IconLinkedIn />
                    </div>
                    <div className={s.socialText}>LinkedIn</div>
                  </a>
                </div>
              </div>

              <div className={s.copyright}>INKR © 2020</div>
            </div>

            {/* LINKS AREA */}
            <div className={s.linksArea}>
              {getLinkList.map((linkColumn, idx: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <ul key={idx} className={s.linkList}>
                  {linkColumn.map(renderLink)}
                </ul>
              ))}
            </div>
          </div>
          <div className={s.footerMob}>
            {renderLogo}
            <div className={s.copyrightMob}>INKR © 2020</div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Footer;
