// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { clBlack, fontComic } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/TheCrisis";
const NAME_IMG = "the-crisis";
const PATH_IMG_MOB = "/images/TheCrisis/mobile";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImg2 = styled.div`
  margin: 80px auto 20px;
  max-width: 964px;
  min-height: 142px;
  @media screen and (max-width: 768px) {
    margin: 54px auto -10px;
    min-height: 99px;
  }
`;
const ContainImg3 = styled.div`
  max-width: 905px;
  margin: auto;
  min-height: 628px;
  @media screen and (max-width: 768px) {
    min-height: 288px;
  }
`;
const ContainImg4 = styled.div`
  margin: 50px auto 5px;
  margin-left: 30px;
  max-width: 964px;
  min-height: 142px;
  @media screen and (max-width: 768px) {
    margin: 50px auto -8px;
    min-height: 99px;
  }
`;
const ContainImg5 = styled.div`
  min-height: 650px;
  @media screen and (max-width: 768px) {
    min-height: 335px;
  }
`;
const ContainImg6 = styled.div`
  margin: 110px auto 87px;
  max-width: 1000px;
  min-height: 721px;
  @media screen and (max-width: 768px) {
    margin: 35px auto 27px;
    min-height: 413px;
  }
`;
const ContainImg8 = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 387px;
  @media screen and (max-width: 768px) {
    min-height: 349px;
  }
`;
const ContainImg9 = styled.div`
  max-width: 964px;
  margin: 65px auto 20px;
  min-height: 127px;
  @media screen and (max-width: 768px) {
    margin: -10px auto 20px;
    min-height: 56px;
  }
`;
const ContainImg10 = styled.div`
  max-width: 943px;
  min-height: 727px;
  @media screen and (max-width: 768px) {
    min-height: 358px;
  }
`;
const ContainImg11 = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 244px;
  @media screen and (max-width: 768px) {
    margin-top: 23px;
    min-height: 133px;
  }
`;

const Caption = styled.p`
  font-family: ${fontComic};
  max-width: 964px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 44px;
  text-align: center;
  color: ${clBlack};
  margin: 100px auto;
  @media screen and (max-width: 768px) {
    margin: 50px auto 13px;
    font-size: 14px;
    line-height: 24px;
  }
`;

const HeaderImg = styled.div`
  max-width: 818px;
  margin: auto;
  margin-bottom: 84px;
  min-height: 100px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
    min-height: 89px;
  }
`;

const TheCrisis = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.the_crisis_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.the_crisis_des}
        highlight={lang.the_crisis_highlight}
        style={{ maxWidth: "1090px" }}
        CompTop={(
          <HeaderImg>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} header top`} />
              </picture>
            </LazyLoad>
          </HeaderImg>
)}
      >
        <OurNewVisionContainer>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-2.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-2.png`} alt={`${NAME_IMG} 2`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg3>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
              </picture>
            </LazyLoad>
          </ContainImg3>
          <ContainImg4>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
              </picture>
            </LazyLoad>
          </ContainImg4>
          <ContainImg5>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-5.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-5.png`} alt={`${NAME_IMG} 5`} />
              </picture>
            </LazyLoad>
          </ContainImg5>
          <ContainImg6>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-6.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-6.png`} alt={`${NAME_IMG} 6`} />
              </picture>
            </LazyLoad>
          </ContainImg6>

          <ContainImg8>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-8.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-8.png`} alt={`${NAME_IMG} 8`} />
              </picture>
            </LazyLoad>
          </ContainImg8>
          <ContainImg9>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-9.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-9.png`} alt={`${NAME_IMG} 9`} />
              </picture>
            </LazyLoad>
          </ContainImg9>
          <ContainImg10>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-10.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-10.png`} alt={`${NAME_IMG} 10`} />
              </picture>
            </LazyLoad>
          </ContainImg10>
          <Caption>
            {lang.the_crisis_caption_1}
            <br />
            ---
            <br />
            {lang.the_crisis_caption_2}
          </Caption>

          <ContainImg11>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-11.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-11.png`} alt={`${NAME_IMG} 11`} />
              </picture>
            </LazyLoad>
          </ContainImg11>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default TheCrisis;
