import React from "react";


const IconTwitter = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.0301 8.41493C15.0301 11.5999 12.605 15.2749 8.17005 15.2749C6.81005 15.2749 5.54005 14.8749 4.48505 14.1849C4.67505 14.2099 4.86505 14.2199 5.06005 14.2199C6.19005 14.2199 7.23005 13.8349 8.05505 13.1899C7.00005 13.1699 6.11005 12.4749 5.80505 11.5149C5.95505 11.5449 6.10505 11.5599 6.26005 11.5599C6.48005 11.5599 6.69505 11.5299 6.89505 11.4749C5.79005 11.2499 4.96005 10.2799 4.96005 9.10993V9.07993C5.28505 9.25993 5.65505 9.36993 6.05005 9.37993C5.40005 8.94993 4.97505 8.20993 4.97505 7.37493C4.97505 6.93493 5.09505 6.51993 5.30005 6.16493C6.49005 7.62493 8.26505 8.58493 10.27 8.68493C10.225 8.50993 10.205 8.32493 10.205 8.13493C10.205 6.80493 11.285 5.72493 12.615 5.72493C13.31 5.72493 13.935 6.01493 14.375 6.48493C14.925 6.37493 15.4401 6.17493 15.9051 5.89993C15.725 6.46493 15.345 6.93493 14.845 7.23493C15.335 7.17493 15.8001 7.04493 16.2301 6.85493C15.9051 7.33993 15.4951 7.76493 15.0251 8.10493C15.0301 8.20493 15.0301 8.30993 15.0301 8.41493Z"
      fill="white"
    />
  </svg>
);

export default IconTwitter;
