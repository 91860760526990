// @ts-nocheck
import { configLazyLoad } from "constants/config";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/TheFuture";
const NAME_IMG = "the-future";
const PATH_IMG_MOB = "/images/TheFuture/mobile";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;
const ContainImg1 = styled.div`
  max-width: 960px;
  margin: 55px auto 80px;
  @media screen and (max-width: 768px) {
    margin: 34px auto 49px;
    max-width: 335px;
  }
`;

// const ContainImg2 = styled.div`
//   max-width: 200px;
//   margin: 28px auto 48px;
//   @media screen and (max-width: 768px) {
//     margin: 0 auto;
//     max-width: 100px;
//   }
// `;

// const ContainImg3 = styled.div`
//   max-width: 964px;
//   margin: 0 auto 40px;
//   @media screen and (max-width: 768px) {
//     margin: 20px auto 16px;
//     max-width: 335px;
//   }
// `;

const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  @media screen and (max-width: 768px) {
    /* margin: 20px auto 34px; */
    font-size: 20px;
    line-height: 23px;
  }
`;

const Description2 = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    margin-bottom:30px
  }
`;

const DescriptionAround = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  max-width: 606px;
  margin: 0;
  @media screen and (max-width: 1024px) {
    margin: 16px auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 19px;
  }
`;

const LineTop = styled.div`
  height: 5px;
  background: #597ef7;
  width: 198px;
  margin: auto;
  margin-bottom: 60px;
  @media screen and (max-width: 1024px) {
    width: 96px;
    margin-bottom: 50px;
  }
`;

// const LineBottom = styled.div`
//   height: 5px;
//   background: #597ef7;
//   width: 198px;
//   margin: 90px auto 60px;
//   @media screen and (max-width: 1024px) {
//     width: 96px;
//     margin: 40px auto 30px;
//   }
// `;

const TextBlue = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 49px;
  text-align: center;
  color: #597ef7;
  padding-bottom: 22px;
  @media screen and (max-width: 768px) {
    padding-bottom: 30px;
    font-size: 24px;
    line-height: 28px;
    margin: 0;
  }
`;

const GroupAround = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
  @media screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    margin-top: 40px;
  }
  @media screen and (max-width: 768px) {
    margin: 0;

    &:nth-child(2) {
      margin: 70px auto;
    }
  }
`;

const ContainContent = styled.div`
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Contain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContainImgAroundIcon = styled.div`
  max-height: 80px;
  margin-top: 30px;
  @media screen and (max-width: 1024px) {
    margin: 0 4px;
  }
  @media screen and (max-width: 768px) {
    max-height: 44px;
  }
`;

const ContainImgAroundMedia = styled.div`
  max-width: 78px;
  margin-top: 30px;
  @media screen and (max-width: 768px) {
    max-width: 40px;
    margin-top: 0;
  }
`;

const ContainImgAround = styled.div`
  max-width: 308px;
  @media screen and (max-width: 768px) {
    max-width: 120px;
  }
`;

const GroupDownload = styled.div`
  display: flex;
  max-width: 542px;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    max-width: 291px;
  }
`;

const GroupMedia = styled.div`
  display: flex;
  width: 298px;
  justify-content: space-between;
  @media screen and (max-width: 1024px) {
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    width: 156px;
  }
`;

const ButtonJoin = styled.button`
  background: #597ef7;
  border-radius: 10px;
  border: 0;
  padding: 20px 43px;

  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  color: #ffffff;
  margin-top: 30px;
  outline: 0;
  :focus {
    outline: none;
  }
  @media screen and (max-width: 1024px) {
    margin: auto;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 40px;
    padding: 4px 18px;
  }
`;

const TheFuture = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout title={lang.the_future_header} srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}>
        <OurNewVisionContainer>
          <Description>
            {lang.the_future_1}
            <br />
            <br />
            {lang.the_future_2}
          </Description>
          <ContainImg1>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
              </picture>
            </LazyLoad>
          </ContainImg1>

          <Contain>
            <LineTop />
            <TextBlue>{lang.the_future_3}</TextBlue>

            <Description2>{lang.the_future_4}</Description2>
          </Contain>

          <div>
            <GroupAround>
              <ContainImgAround>
                <LazyLoad {...configLazyLoad}>
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-around-1.png`} alt={`${NAME_IMG} around 1`} />
                </LazyLoad>
              </ContainImgAround>
              <ContainContent>
                <DescriptionAround>{lang.the_future_5}</DescriptionAround>
                <GroupMedia>
                  <ContainImgAroundMedia>
                    <a href="https://www.facebook.com/inkrcomics" target="_blank" rel="noopener noreferrer">
                      <LazyLoad {...configLazyLoad}>
                        <Artwork src={`${PATH_IMG}/${NAME_IMG}-fb.png`} alt={`${NAME_IMG} media fb`} />
                      </LazyLoad>
                    </a>
                  </ContainImgAroundMedia>
                  <ContainImgAroundMedia>
                    <a href="https://twitter.com/inkr" target="_blank" rel="noopener noreferrer">
                      <LazyLoad {...configLazyLoad}>
                        <Artwork src={`${PATH_IMG}/${NAME_IMG}-twitter.png`} alt={`${NAME_IMG} media twitter`} />
                      </LazyLoad>
                    </a>
                  </ContainImgAroundMedia>
                  <ContainImgAroundMedia>
                    <a href="https://www.reddit.com/r/inkr/" target="_blank" rel="noopener noreferrer">
                      <LazyLoad {...configLazyLoad}>
                        <Artwork src={`${PATH_IMG}/${NAME_IMG}-reddit.png`} alt={`${NAME_IMG} media reddit`} />
                      </LazyLoad>
                    </a>
                  </ContainImgAroundMedia>
                </GroupMedia>
              </ContainContent>
            </GroupAround>

            <GroupAround>
              <ContainImgAround>
                <LazyLoad {...configLazyLoad}>
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-around-2.png`} alt={`${NAME_IMG} around 2`} />
                </LazyLoad>
              </ContainImgAround>
              <ContainContent>
                <DescriptionAround>{lang.the_future_6}</DescriptionAround>
                <GroupDownload>
                  <ContainImgAroundIcon>
                    <a href="https://smarturl.it/inkrios" target="_blank" rel="noopener noreferrer">
                      <LazyLoad {...configLazyLoad}>
                        <Artwork src={`${PATH_IMG}/${NAME_IMG}-ios.png`} alt={`${NAME_IMG} media icon`} />
                      </LazyLoad>
                    </a>
                  </ContainImgAroundIcon>
                  <ContainImgAroundIcon>
                    <a href="https://smarturl.it/inkrandroid" target="_blank" rel="noopener noreferrer">
                      <LazyLoad {...configLazyLoad}>
                        <Artwork src={`${PATH_IMG}/${NAME_IMG}-android.png`} alt={`${NAME_IMG} media icon`} />
                      </LazyLoad>
                    </a>
                  </ContainImgAroundIcon>
                </GroupDownload>
              </ContainContent>
            </GroupAround>

            <GroupAround>
              <ContainImgAround>
                <LazyLoad {...configLazyLoad}>
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-around-3.png`} alt={`${NAME_IMG} around 3`} />
                </LazyLoad>
              </ContainImgAround>
              <ContainContent>
                <DescriptionAround>{lang.the_future_7}</DescriptionAround>
                <a href="https://smarturl.it/inkrcareers" target="_blank" rel="noopener noreferrer">
                  <ButtonJoin>{lang.the_future_button_join}</ButtonJoin>
                </a>
              </ContainContent>
            </GroupAround>
          </div>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default TheFuture;
