// @ts-nocheck
import { configLazyLoad } from "constants/config";
import lang from "constants/lang/en";
import { END_HISTORY } from "constants/scrollIndicator";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import { backgroundEven, colorPrimary } from "../../constants/style";
import AppsCarousel from "../AppsCarousel";
// @ts-ignore
import mobile2009 from "./comic2009.mobile.png";
// @ts-ignore
import comic2009 from "./comic2009.png";
// @ts-ignore
import mobile2010 from "./comic2010.mobile.png";
// @ts-ignore
import comic2010 from "./comic2010.png";
// @ts-ignore
import mobile2011a from "./comic2011a.mobile.png";
// @ts-ignore
import comic2011a from "./comic2011a.png";
// @ts-ignore
import mobile2011b from "./comic2011b.mobile.png";
// @ts-ignore
import comic2011b from "./comic2011b.png";
// @ts-ignore
import mobile2011c from "./comic2011c.mobile.png";
// @ts-ignore
import comic2011c from "./comic2011c.png";
// @ts-ignore
import mobile2016 from "./comic2016.mobile.png";
// @ts-ignore
import comic2016 from "./comic2016.png";
// @ts-ignore
import mobileConclusion from "./comicConclusion.mobile.png";
// @ts-ignore
import comicConclusion from "./comicConclusion.png";
// @ts-ignore
import mobileComicConclusionDes from "./comicConclusionDes.mobile.png";
// @ts-ignore
import comicConclusionDes from "./comicConclusionDes.png";
// @ts-ignore
import logo from "./journey.svg";


const Row = styled.div`
  align-items: center;

  padding-top: 163px;
  padding-bottom: 150px;

  @media screen and (max-width: 768px) {
    padding-top: 50px;
    padding-bottom: 20px;
    margin-top: 0;
  }
`;

const RowYellow = styled.div`
  align-items: center;
  padding-top: 42px;
  padding-bottom: 80px;
  margin-top: -150px;

  @media screen and (max-width: 768px) {
    margin-top: 0;
    padding-top: 50px;
    padding-bottom: 20px;
  }
`;

const LogoWrapper = styled.div`
  margin: 0 auto;
`;

const Header = styled.h1`
  margin-top: 30px;
  margin-bottom: 120px;
  width: 100%;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;
  text-transform: uppercase;
  text-align: center;
  color: ${colorPrimary};

  @media (max-width: 768px) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 36px;
  }
`;

const Year = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  color: #2f54eb;
  position: relative;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
  }

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 31px;
    height: 31px;
    background-color: #2f54eb;
    border-radius: 50%;
    left: -70px;
    top: -4px;
  }

  @media screen and (max-width: 900px) {
    ::before {
      display: none;
    }
  }
`;

const Title = styled.h2`
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  margin-top: 28px;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.87);

  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const Spacing = styled.div`
  height: 30px;

  @media (max-width: 768px) {
    height: 16px;
  }
`;

const Artwork = styled.img`
  width: 100%;
  height: auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Conclusion = styled.div`
  font-weight: bold;
  font-size: 30px;
  line-height: 35px;
  color: #000000;
  position: relative;
  margin-top: 35px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 22px;
    margin-top: 21px;
  }

  ::before {
    content: "";
    display: block;
    position: absolute;
    width: 31px;
    height: 31px;
    background-color: #2f54eb;
    border-radius: 50%;
    left: -60px;
    top: 19px;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }

  ::after {
    content: "";
    display: block;
    position: absolute;
    width: 55px;
    height: 55px;
    background-color: rgba(33, 150, 243, 0.2);
    border-radius: 50%;
    left: -72px;
    top: 7px;

    @media screen and (max-width: 900px) {
      display: none;
    }
  }
`;

const LongLine = styled.div`
  width: 1px;
  border-left: 1px dashed #2f54eb;
  position: absolute;
  height: calc(100% + 175px);
  right: 38px;

  @media screen and (max-width: 769px) {
    display: none;
  }
`;

const Block = styled.div`
  margin-top: 124px;

  @media (max-width: 768px) {
    margin-top: 51px;
  }
`;

const LongLineWrapper = styled.div`
  position: relative;
`;

const TextLast = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  color: #000;
  max-width: 964px;
  border-left: 10px solid #2f54eb;
  padding: 30px 0 50px 30px;
  margin: 172px auto 70px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 23px;
    border-left: 5px solid #2f54eb;
    padding: 10px 0 10px 16px;
    margin: 50px auto;
  }
`;

const ContainImg = styled.div`
 max-width: 964px;
  margin: auto;
  min-height: 220px;
  @media (max-width: 768px) {
    min-height: 206px;
  }
`;

const ContainImg2011 = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 2051px;
  @media (max-width: 1024px) {
    min-height: 1714px;
  }
  @media (max-width: 768px) {
    min-height: 1238px;
  }
`;

const ContainImg2016 = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 1086px;
  @media (max-width: 1024px) {
    min-height: 913px;
  }
  @media (max-width: 768px) {
    min-height: 1120px;
  }
`;

const ContainImgConclusion = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 1916px;
  @media (max-width: 1024px) {
    min-height: 1613px;
  }
  @media (max-width: 768px) {
    min-height: 1286px;
  }
`;

const OurJourney = React.memo(() => (
  <>
    <div style={{ backgroundColor: backgroundEven }}>
      <div className="container">
        <Row className="row">
          <LogoWrapper>
            <img src={logo} alt="MR Comics Logo" />
          </LogoWrapper>

          <Header className="col-sm-12">{lang.the_history}</Header>

          <div className="col-sm-12">
            <div className="row" style={{ margin: 0 }}>
              <LongLineWrapper className="col-sm-0 col-lg-2">
                <LongLine />
              </LongLineWrapper>
              <div className="col-sm-12 col-lg-8">
                <Block style={{ marginTop: 0 }}>
                  <Year>2009</Year>
                  <Title>{lang.our_journey_2009_1}</Title>
                  <Text>{lang.our_journey_2009_2}</Text>
                  <picture>
                    <source srcSet={mobile2009} media="(max-width: 768px)" />
                    <Artwork src={comic2009} alt="2009" />
                  </picture>
                  <Text>
                    {lang.our_journey_2009_3}
                    <Spacing />
                    {lang.our_journey_2009_4}
                    <Spacing />
                    {lang.our_journey_2009_5}
                  </Text>
                </Block>

                <Block>
                  <Year>2010 - 2011</Year>
                  <Title>{lang.our_journey_2010_1}</Title>
                  <Text>{lang.our_journey_2010_2}</Text>
                  <picture>
                    <source srcSet={mobile2010} media="(max-width: 768px)" />
                    <Artwork src={comic2010} alt="2010-2011" />
                  </picture>
                  <Text>
                    {lang.our_journey_2010_3}
                    <Spacing />
                    {lang.our_journey_2010_4}
                    <Spacing />
                    {lang.our_journey_2010_5}
                  </Text>
                </Block>

                <Block>
                  <Year>2011 - 2016</Year>
                  <Title>{lang.our_journey_2011_1}</Title>
                  <Text>{lang.our_journey_2011_2}</Text>
                  <picture>
                    <source srcSet={mobile2011a} media="(max-width: 768px)" />
                    <Artwork src={comic2011a} alt="2011a" />
                  </picture>
                  <Text>{lang.our_journey_2011_3}</Text>
                  <picture>
                    <source srcSet={mobile2011b} media="(max-width: 768px)" />
                    <Artwork src={comic2011b} alt="2011b" />
                  </picture>
                  <Text>{lang.our_journey_2011_4}</Text>
                  <p
                    style={{
                      marginTop: "40px",
                      fontWeight: 500,
                      fontSize: "24px",
                      lineHeight: "27px",
                      color: "#000000",
                    }}
                  >
                    {lang.our_journey_2011_5}
                  </p>

                  <AppsCarousel />

                  <Text>
                    {lang.our_journey_2011_6}
                    <Spacing />
                    {lang.our_journey_2011_7}
                    <Spacing />
                    {lang.our_journey_2011_8}
                  </Text>
                  <ContainImg2011>
                    <LazyLoad {...configLazyLoad}>
                      <picture>
                        <source srcSet={mobile2011c} media="(max-width: 768px)" />
                        <Artwork src={comic2011c} alt="2011c" />
                      </picture>
                    </LazyLoad>
                  </ContainImg2011>
                </Block>

                <Block>
                  <Year>2016</Year>
                  <Title>{lang.our_journey_2016_1}</Title>
                  <Text>
                    {lang.our_journey_2016_2}
                    <Spacing />
                    {lang.our_journey_2016_3}
                    <Spacing />
                    {lang.our_journey_2016_4}
                  </Text>
                  <ContainImg2016>
                    <LazyLoad {...configLazyLoad}>
                      <picture>
                        <source srcSet={mobile2016} media="(max-width: 768px)" />
                        <Artwork src={comic2016} alt="2016" />
                      </picture>
                    </LazyLoad>
                  </ContainImg2016>
                </Block>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </div>

    <div style={{ background: "#fff" }}>
      <div className="container">
        <RowYellow className="row">
          <LongLineWrapper className="col-sm-0 col-lg-2" />
          <div className="col-sm-12 col-lg-8">
            <Text>{lang.our_journey_2016_5}</Text>
            <Conclusion>{lang.our_journey_2016_6}</Conclusion>
            <ContainImgConclusion>
              <LazyLoad {...configLazyLoad}>
                <picture>
                  <source srcSet={mobileConclusion} media="(max-width: 768px)" />
                  <Artwork src={comicConclusion} alt="Impact of Piracy" />
                </picture>
              </LazyLoad>
            </ContainImgConclusion>
          </div>
          <div className="col-sm-12">
            <TextLast>
              <p>{lang.our_journey_2016_7_1}</p>
              <i style={{ fontWeight: "400" }}>{lang.our_journey_2016_7_2}</i>
              <i>{lang.our_journey_2016_7_3}</i>
            </TextLast>
            <ContainImg>
              <LazyLoad {...configLazyLoad}>
                <picture>
                  <source srcSet={mobileComicConclusionDes} media="(max-width: 768px)" />
                  <Artwork src={comicConclusionDes} alt="Impact of Piracy" />
                </picture>
              </LazyLoad>
            </ContainImg>
            <div id={`${END_HISTORY}`} />
          </div>
        </RowYellow>
      </div>
    </div>
  </>
));

export default OurJourney;
