const JOURNEY = "our_journey";
const NEW_VISION = "new_vision";
const INITIAL_PLAN = "initial_plan";
const FIRST_FAILURE = "first_failure";
const MANGA_ROCK = "manga_rock";
const NEW_APPROACH = "new_approach";
const THE_CRISIS = "the_crisis";
const SURVIVAL_MODE = "survival_mode";
const THE_FUTURE = "the_future";
const END_HISTORY = "end_history";

export {
  JOURNEY,
  NEW_VISION,
  INITIAL_PLAN,
  FIRST_FAILURE,
  MANGA_ROCK,
  NEW_APPROACH,
  THE_CRISIS,
  SURVIVAL_MODE,
  THE_FUTURE,
  END_HISTORY,
};
