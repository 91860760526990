/* eslint-disable @typescript-eslint/camelcase */
export default {
  lang: "ja",
  home: "ホム",
  the_history: "The History",
  initial_plan: "The Initial Plan",
  first_failure: "The First Failure",
  manga_rock: "The Death of Manga Rock",
  new_approach: "The New Approach",
  crisis: "The New Crisis",
  survival_mode: "Survival Mode",
  future: "The Unknown Future",

  intro_wrong_text: "過ちを改めるスキャン収集サイトについてのストーリー",
  intro_apology:
    "まず、私たち自身のようなアグリゲーターサイトが漫画業界やクリエイターに与えたマイナスの影響についての言い訳はありません。",
  intro_apology_2: "多大なご迷惑をおかけして、深くお詫び申し上げます。",

  in_depth_header: "より詳細なストーリーについては、私たちの投稿をご覧ください",
  in_depth_cta: "もっと他のブログ投稿",

  our_journey_2009_1: "iPhoneアプリの構築方法を学ぶ",
  our_journey_2009_2:
    "10年前にApp Storeが立ち上げられ、誰もがすべてのiPhoneユーザーにアプリを配布できるようになりました。大学の友人のグループは、世界中の何百万人もの人々のために製品を構築する見通しに興奮していました。",
  our_journey_2009_3: "それで、何を構築するかを決める時が来たとき、私たちはすぐに漫画にしました。",
  our_journey_2009_4:
    "私たち全員が漫画を読んで育ちました。当時はonemanga.comのようなPCだけのスキャンサイトでまだ読んでいました。でも、バスに乗って学校に行くお気に入りのタイトルを読む方法が欲しかったです。",
  our_journey_2009_5: "それが私たちが学習プロジェクトとしてManga Rockを始めた理由です。",
  our_journey_2010_1: "Manga Rockアプリの起動",
  our_journey_2010_2:
    "次の数か月間、私たちは学び、学び、自分たちが構築したものに満足しました。誰かが実際に私たち自身の製品を使用したいかどうかを確認するために、２０１０年４月にApp StoreでManga Rockの最初のバージョンを公開しました。",
  our_journey_2010_3: "驚いたことに、多くの人がアプリをダウンロードして使用しました。わくわくしました！",
  our_journey_2010_4: "最初のバージョンの後、Appleは最初のiPadをリリースしました。",
  our_journey_2010_5: "それで、同じ学習意欲を持って、次にiPad用のManga Rockを作成することにしました。",
  our_journey_2011_1: "「F*cking awesome app」を構築する会社を設立",
  our_journey_2011_2:
    "私たちが始めたとき、それはたった3人の友人であり、製品を学び、構築したいという私たちの願いでした。会社を設立したり、お金を稼いだり、世界を変えたりするような野心的な理想は全然ありませんでした。",
  our_journey_2011_3:
    "ただし、最初のManga Rockアプリを2年間学習して作成した後、素晴らしい製品を作成するためには、他の人の助けが必要であることを私たちは知っていました。有機的に、私たちはチームとして成長し、その過程でより多くの知識と経験を獲得しました。",
  our_journey_2011_4:
    "そのため、次の5年間で、Manga Rockの作業をやめ、「クール」だと思った多くのアイデアを試し始めました。",
  our_journey_2011_5: "では、以下の作ったものを見つけてください。",
  our_journey_2011_6:
    "チームとしての最初の6〜7年間で、多数の製品、多くの失敗、数百万のユーザーを抱えて、さまざまなことを学びました。そのすべてが私たちを今の私たちに導いてくれました。",
  our_journey_2011_7: "漫画スキャンのアグリゲーターであるManga Rockでご存知かもしれません。",
  our_journey_2011_8:
    "しかし、心の底では、私たちはデザイン主導のテック企業であり、世界規模で視聴者に影響を与えることができます。",
  our_journey_2016_1: "コミックに再び焦点を当てる",
  our_journey_2016_2:
    "この時点まで、Manga Rockを積極的に開発しておらず、ストアに置いたばかりでしたが、このアプリは世界中の何百万人もの読者を蓄積し続けました。",
  our_journey_2016_3:
    "ただし、多くの異なる製品に取り組んでいますが、深く掘り下げても、Manga Rockは今でも共鳴して最も戻ってくる唯一のアプリです。",
  our_journey_2016_4:
    "それには理由があります。情熱的な読者として、私たちはストーリーテリング媒体としてのコミックの可能性を真に信じています。",
  our_journey_2016_5:
    "コミックに対する私たちの信念と愛、および大規模にプラスの影響を与えることができる製品を構築するという私たちの内部的な意欲から、Manga Rockをさらに活用したいと考えていました。",
  our_journey_2016_6:
    "それで、私たちは漫画業界についてより深く知り、より多くのことを学びました。それは私たちがどれほど無知だったかを私たちにぶつけます。",
  our_journey_2016_7: "特別に、私たちの長年にわたった蓄積してきた製品の構築の学習と経験により、",
  our_journey_2016_8:
    "私たちは著作権侵害をサポートすることではなく、著作権侵害と戦うユニークな立場にいることが見られます。",

  our_new_vision_header: "OUR NEW VISION",
  our_new_vision_des: "To fix our wrongdoings, we decided to build an entirely new platform. ",

  initial_plan_header: "The Initial plan",
  initial_plan_des: "We knew what we wanted. We knew our strengths. We had ideas.",

  first_failure_header: "The first failure",
  first_failure_des: "We started pitching our platform to publishers and creators all over the world.",

  death_of_manga_rock_header: "The death of manga rock",
  death_of_manga_rock_des: "We had only one more card to play.",
  death_of_manga_rock_caption_1: "AND With this one decision, we would lose nearly everything...",
  death_of_manga_rock_caption_2: "With MR, we learned how to build things, we evolved, and became who we are today. ",

  new_approach_header: "The New approach",
  new_approach_caption_1: "But all was not well.",

  the_crisis_header: "THE CRISIS",
  the_crisis_des:
    "Ken, OUR CEO, finished the localization project. Finally, he had a chance to look at INKR Comics, Backstage, and content. That was when he realized",
  the_crisis_highlight: "we were in serious trouble.",
  the_crisis_caption_1:
    "We were too confident when we made all those promises. We understood readers, but the global publishing industry was insanely complex. All our plans kept changing.",
  the_crisis_caption_2:
    "On top of that, we were running out of money. If we kept trying to do everything, we’d die before we delivered.",

  survival_mode_header: "SURVIVAL MODE",
  survival_mode_caption_1: "When we said no to coins, we also said no to the great titles these creators made",
  survival_mode_caption_2:
    "If creators wouldn’t work with us, we wouldn’t be able to bring their content to readers. Their comics would remain inaccessible.",
  survival_mode_caption_3:
    "In the end, we felt that all creators should be able to earn a fair wage for their investment of time and passion.",
  survival_mode_caption_4: "We reorganized and changed our timeline. Our entire company was running to the same place:",
  survival_mode_caption_5:
    "AS THRILLED AS WE ARE TO SEE OUR PRODUCTS IN THE HAND OF USERS, NOW WE HAVE TO FACE A MUCH BIGGER CHALLENGE:",
  survival_mode_caption_6: "WE HAVE TO LEARN HOW TO OPERATE AND GROW THE PLATFORM WE HAVE BUILT.",

  the_future_header: "THE FUTURE",
  the_future_des:
    "In order to run a platform - something we have zero experience in, we have to graduate from being purely builders.",
  the_future_highlight:
    "Difficult as it sounds, this ambitious and challenging future gives each of us the motivation to keep moving forward. ",
  the_future_1:
    " In order to run a platform - something we have zero experience in, we have to graduate from being purely builders.",
  the_future_2:
    " Difficult as it sounds, this ambitious and challenging future gives each of us the motivation to keep moving forward.",
  the_future_3: "But no matter how hard we try, we can’t do it all alone.",
  the_future_4: "The help from fellow comic fans like you also matters to us.",
  the_future_5:
    " By following & spreading our story, you can help us convince more publishers and creators to put their content on INKR Comics.",
  the_future_6:
    "By reading on INKR Comics, you show appreciation and support creators, and you help us improve the platform.",
  the_future_7: "Or if you are as passionate as we are about the cause, why not join the INKR team?",
  the_future_button_join: "Join our team",
  the_future_button_pitch: "See our investor pitch",

  footer_text_1: "漫画を世界中に手が届け",
  footer_text_2: "るものにします。",
  footer_text_3: "MR COMICSブログ",
};
