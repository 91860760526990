import React from "react";


const LogoInkr = ({ color = "white" }: { color?: string }) => (
  <svg width="107" height="32" viewBox="0 0 107 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8609 1.06842C19.926 -0.600367 22.9529 -0.279073 24.6217 1.78605L34.1031 13.5192V4.80765C34.1031 2.15255 36.2555 0.000162067 38.9106 0.000162172C41.5657 0.000162277 43.718 2.15255 43.718 4.80765V27.1174C43.718 29.1547 42.4338 30.9708 40.513 31.6499C38.5922 32.329 36.4518 31.7236 35.1713 30.139L25.6901 18.4059V27.1549C25.6901 29.81 23.5377 31.9624 20.8826 31.9624C18.2275 31.9624 16.0751 29.81 16.0751 27.1549V4.92031L16.0753 4.87707C16.0557 3.45244 16.6668 2.03337 17.8609 1.06842ZM9.61497 4.92031C9.61497 2.26521 7.46258 0.112822 4.80748 0.112823C2.15238 0.112823 0 2.26521 0 4.92031V27.1549C0 29.81 2.15238 31.9624 4.80748 31.9624C7.46258 31.9624 9.61497 29.81 9.61497 27.1549V4.92031ZM69.2743 1.78605C70.9431 -0.279073 73.97 -0.600367 76.0352 1.06842C78.1003 2.7372 78.4216 5.76413 76.7528 7.82925L58.7247 30.139C57.4442 31.7236 55.3039 32.329 53.3831 31.6499C51.4622 30.9708 50.178 29.1547 50.178 27.1174V4.80765C50.178 2.15255 52.3304 0.000162264 54.9855 0.000162158C57.6406 0.000162053 59.793 2.15255 59.793 4.80765V13.5192L69.2743 1.78605ZM73.2811 31.9999C70.1697 31.9999 67.53 29.727 67.53 26.2894C67.53 22.8518 73.2811 16.9765 73.2811 16.9765C73.2811 16.9765 78.7975 22.9212 78.7975 26.2894C78.7975 29.6577 76.3926 31.9999 73.2811 31.9999ZM88.7882 0.601097L88.8158 0.60118H95.699V0.601448L95.7741 0.60118C101.458 0.60118 106.065 5.20863 106.065 10.8922C106.065 14.3712 104.339 17.447 101.696 19.3097L105.947 24.6474C107.498 26.5945 107.177 29.43 105.23 30.9808C103.283 32.5316 100.447 32.2104 98.8965 30.2633L93.2952 23.2308V27.493C93.2952 29.9821 91.2773 32 88.7882 32C86.299 32 84.2812 29.9821 84.2812 27.493V5.10811C84.2812 2.61896 86.299 0.601097 88.7882 0.601097Z"
      fill={color}
    />
  </svg>
);

export default LogoInkr;
