// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { backgroundEven, clBlack } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "LanguageContext";

// @ts-ignore
import Story1 from "./story1.jpg";
// @ts-ignore
import Story2 from "./story2.jpg";
// @ts-ignore
import Story3 from "./story3.jpg";


const PATH_IMG = "/images/TheLast";
const NAME_IMG = "the-last";

const Container1 = styled.div`
  padding-top: 130px;
  padding-bottom: 110px;
  background: ${backgroundEven};
  @media (max-width: 768px) {
    padding-top: 43px;
    padding-bottom: 27px;
  }
`;

const Container2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 130px;
  padding-bottom: 110px;
  max-width: 964px;
  margin: auto;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 60px;
  }
`;

const InDepthContainer = styled.div``;

const Row = styled.div``;

const Header = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
  }
`;

const StoriesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 50px auto 0;
  padding-left: 20px;
  padding-right: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 16px;
  }
`;

const Story = styled.a`
  width: 308px;
  height: 367px;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.02);
  border-radius: 2px;
  margin-right: 10px;
  margin-left: 10px;
  :hover {
    text-decoration-line: none;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    margin: 10px auto;
  }
`;

const StoryArtwork = styled.img`
  height: 200px;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const StoryContent = styled.div`
  padding: 20px;
`;

const StoryTitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: rgba(0, 0, 0, 0.87);
`;

const StoryText = styled.div`
  font-size: 16px;
  line-height: 21px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: rgba(0, 0, 0, 0.87);
`;

const CTA = styled.a`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #1061ff;
  display: block;
  margin: 0 auto;
  width: 200px;
  text-align: center;
  cursor: pointer;
  margin-top: 40px;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: ${clBlack};
  margin-bottom: 52px;
  @media (max-width: 768px) {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }
`;

const ContentCheckOut = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0 20px;
  }
`;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;
const ContainImg = styled.div`
  max-width: 268px;
  margin: 20px;
  margin-bottom: 0;
`;

const ContainImgIcon = styled.div`
  max-width: 126px;
`;

const GroupCheckOut = styled.a`
  display: flex;
  flex-direction: column;
  flex-basis: 308px;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.02), 0px 0px 2px rgba(0, 0, 0, 0.14), 0px 2px 2px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  text-decoration: none;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);

  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.87);
  }
  @media (max-width: 768px) {
    margin: 10px 0;
    flex-basis: auto;
  }
`;

const GroupTop1 = styled.div`
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 200px;
`;
const GroupTop2 = styled.div`
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-basis: 200px;
  @media (max-width: 768px) {
    flex-basis: auto;
  }
`;
const GroupBottom = styled.div`
  padding: 14px 17px 46px;
  flex: 1;
  @media (max-width: 768px) {
    padding: 20px;
  }
`;
const LinkCheckout = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-transform: uppercase;
  color: #1061ff !important;
`;

const NameCheckOut = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin: 0;
  @media (max-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
`;

const DesCheckOut = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  margin: 10px auto 18px;
  min-height: 42px;
  color: rgba(0, 0, 0, 0.87);
  @media (max-width: 768px) {
    margin: 4px auto 40px;
    min-height: auto;
  }
`;

const InDepth = React.memo(() => (
  <LanguageContext.Consumer>
    {(lang) => (
      <InDepthContainer>
        <Container1>
          <Row>
            <Header>{lang.in_depth_header}</Header>

            <StoriesContainer>
              <Story
                style={{ marginLeft: "auto" }}
                href="https://medium.com/mrcomics/great-experience-with-comic-family-at-seoul-south-korea-3887eef44dfb"
                target="_blank"
              >
                <LazyLoad {...configLazyLoad}>
                  <StoryArtwork src={Story1} alt="Artwork" />
                </LazyLoad>
                <StoryContent>
                  <StoryTitle>A Memorable Experience with Comic Family</StoryTitle>
                  <StoryText>
                    We were at the Busan Webtoon Festival exhibition when we first met Korean author Lee Ki Ho from
                    Comi...
                  </StoryText>
                </StoryContent>
              </Story>

              <Story
                href="https://medium.com/mrcomics/how-we-met-busan-gyeongnam-comics-artist-union-%EC%B0%90%EC%B0%90-9d417533bffb"
                target="_blank"
              >
                <LazyLoad {...configLazyLoad}>
                  <StoryArtwork src={Story2} alt="Artwork" />
                </LazyLoad>
                <StoryContent>
                  <StoryTitle>What We Learned About the Webtoon Industry</StoryTitle>
                  <StoryText>And how we realized that we knew nothing.</StoryText>
                </StoryContent>
              </Story>

              <Story
                style={{ marginRight: "auto" }}
                href="https://medium.com/mrcomics/zhiyin-animation-battle-through-scanlation-82d39b002050"
                target="_blank"
              >
                <LazyLoad {...configLazyLoad}>
                  <StoryArtwork src={Story3} alt="Artwork" />
                </LazyLoad>
                <StoryContent>
                  <StoryTitle>Battle Through the Scanlations with Zhiyin Animation</StoryTitle>
                  <StoryText>Gaining a foot in the door to legitimacy in the comics industry.</StoryText>
                </StoryContent>
              </Story>
            </StoriesContainer>

            <CTA href="https://medium.com/mrcomics" target="_blank" rel="noopener noreferrer">
              {lang.in_depth_cta} →
            </CTA>
          </Row>
        </Container1>
        <Container2>
          <Title>Check out INKR products</Title>

          <ContentCheckOut>
            <GroupCheckOut href="https://inkr.page.link/official" target="_blank" rel="noopener noreferrer">
              <GroupTop1>
                <ContainImgIcon>
                  <LazyLoad {...configLazyLoad}>
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-inkr-products-1.png`} alt={`${NAME_IMG} 1`} />
                  </LazyLoad>
                </ContainImgIcon>
              </GroupTop1>
              <GroupBottom>
                <NameCheckOut>INKR Comics App</NameCheckOut>
                <DesCheckOut>Read comics anywhere</DesCheckOut>
                <LinkCheckout>Check IT OUT</LinkCheckout>
              </GroupBottom>
            </GroupCheckOut>

            <GroupCheckOut href="https://inkr.com/publishers" target="_blank" rel="noopener noreferrer">
              <GroupTop2>
                <ContainImg>
                  <LazyLoad {...configLazyLoad}>
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-inkr-products-3.png`} alt={`${NAME_IMG} 3`} />
                  </LazyLoad>
                </ContainImg>
              </GroupTop2>
              <GroupBottom>
                <NameCheckOut>INKR For Publishers</NameCheckOut>
                <DesCheckOut>Maximize the global reach of your entire comic catalog</DesCheckOut>
                <LinkCheckout>Check IT OUT</LinkCheckout>
              </GroupBottom>
            </GroupCheckOut>

            <GroupCheckOut href="https://inkr.com/localize" target="_blank" rel="noopener noreferrer">
              <GroupTop2>
                <ContainImg>
                  <LazyLoad {...configLazyLoad}>
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-inkr-products-2.png`} alt={`${NAME_IMG} 3`} />
                  </LazyLoad>
                </ContainImg>
              </GroupTop2>
              <GroupBottom>
                <NameCheckOut>INKR Localize</NameCheckOut>
                <DesCheckOut>Take your comics global</DesCheckOut>
                <LinkCheckout>Check IT OUT</LinkCheckout>
              </GroupBottom>
            </GroupCheckOut>
          </ContentCheckOut>
        </Container2>
      </InDepthContainer>
    )}
  </LanguageContext.Consumer>
));
export default InDepth;
