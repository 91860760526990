// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { backgroundEven } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/OurNewVision";
const PATH_IMG_MOB = "/images/OurNewVision/mobile";
const NAME_IMG = "our-new-vision";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImgHead = styled.div`
  margin: 50px 0 100px;
  min-height: 167px;
  @media screen and (max-width: 768px) {
    margin: 36px 0 48px;
    min-height: 152px;
  }
`;

const ContainImgBody = styled.div`
  max-width: 902px;
  min-height: 1991px;
  @media screen and (max-width: 768px) {
    min-height: 984px;
  }
`;

const ContainImgFooter = styled.div`
  max-width: 900px;
  min-height: 900px;
  margin:auto;
  @media screen and (max-width: 768px) {
    margin-top: 6px;
    min-height: 415px;
  }
`;

const OurNewVision = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.our_new_vision_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.our_new_vision_des}
        style={{
          padding: "0 32px",
          background: backgroundEven,
        }}
        paddingDesMob="0 22px"
      >
        <OurNewVisionContainer>
          <ContainImgHead>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-head.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-head.png`} alt={`${NAME_IMG} head`} />
              </picture>
            </LazyLoad>
          </ContainImgHead>
          <ContainImgBody>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-body.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-body.png`} alt={`${NAME_IMG} body`} />
              </picture>
            </LazyLoad>
          </ContainImgBody>
          <ContainImgFooter>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-footer.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-footer.png`} alt={`${NAME_IMG} footer`} />
              </picture>
            </LazyLoad>
          </ContainImgFooter>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default OurNewVision;
