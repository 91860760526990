/* eslint-disable react/static-property-placement */
// @ts-nocheck
import LogoInkr from "components/Footer/LogoInkr";
import {
  END_HISTORY,
  FIRST_FAILURE,
  INITIAL_PLAN,
  JOURNEY,
  MANGA_ROCK,
  NEW_APPROACH,
  NEW_VISION,
  SURVIVAL_MODE,
  THE_CRISIS,
  THE_FUTURE,
} from "constants/scrollIndicator";
import { clBlack, colorBlack54, colorPrimary } from "constants/style";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
// import { MRComicBlogBtn, MRComicLogoAndSlogan } from "./FooterMob";
import mdlMenu from "./mdlMenu.svg";
/**
 * @typedef {import("styled-components").ThemedStyledFunction<"li", any, {
 *  active?: boolean,
 * }, never>} Li
 */

// const HeaderItem = /** @type {Div} */ (styled.div)`
//   font-style: normal;
//   font-weight: bold;
//   font-size: 16px;
//   line-height: 19px;
//   display: flex;
//   align-items: center;
//   height: 30px;
//   padding-left: 24px;
//   padding-right: 24px;
//   box-sizing: content-box;
//   color: ${(props) => ((props.active ? "#2F54EB" : clBlack))};
//   border-left: ${(props) => ((props.active ? "2px solid #2F54EB" : "2px solid #ddd"))};
//   cursor: pointer;
//   transition: color 0.5s ease, border-bottom 0.5s ease;
//   will-change: color, border-bottom;

//   @media screen and (max-width: 900px) {
//     display: none;
//   }
// `;

const HeaderDesktop = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  position: sticky;
  z-index: 1000;
  left: 31px;
  top: 35%;
  max-width: 220px;
  background: transparent;
  height: 0;
  @media screen and (max-width: 1680px) {
    max-width: 200px;
  }
  @media screen and (max-width: 1612px) {
    max-width: 160px;
  }
  @media screen and (max-width: 1576px) {
    max-width: 140px;
  }
  @media screen and (max-width: 1537px) {
    display: none;
  }
`;

const HeaderMobile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 0px 2px rgba(0, 0, 0, 0.2);
  position: fixed;
  background-color: white;
  z-index: 1000;
  width: 100vw;
  top: 0;

  @media screen and (min-width: 1537px) {
    display: none;
  }
`;

const MobileItem = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: ${(props) => (props.active ? clBlack : colorBlack54)};
  display: flex;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  padding: 12px 16px;
`;

// const LangMobileItem = styled.div`
//   font-size: 13px;
//   line-height: 15px;
//   color: ${(props) => (props.active ? colorPrimary : colorBlack54)};
//   font-weight: 500;
//   padding: 8px;
//   cursor: pointer;
// `;

// const LanguageWrap = styled.div`
//   position: absolute;
//   right: 12px;
//   display: flex;
// `;

const Row = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const Menu = styled.div`
  padding: 24px;
  position: fixed;
  background-color: white;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
`;

const MenuItem = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => (props.active ? clBlack : colorPrimary)};
  padding: 12px 0 12px 16px;
  border-left: ${(props) => (props.active ? "2px solid #000" : "2px solid #ddd")};
  cursor: pointer;

  @media screen and (max-width: 1680px) {
    /* padding: 15px 0 15px 16px; */
  }
`;

const TextMenu = styled.p`
  margin: 0;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 20px;
`;

const MenuItemMob = styled.div`
  font-style: normal;
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: ${(props) => (props.active ? clBlack : colorPrimary)};
  padding: 12px 16px 12px 0;
  cursor: pointer;
`;

const CloseButton = styled.div`
  left: 24px;
  top: 15px;
  position: fixed;
  cursor: pointer;
  font-size: 30px;
  color: ${colorBlack54};
`;

const FooterContain = styled.div`
  width: 100%;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.38);
  padding: 20px 0 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.85);
  position: fixed;
  bottom: 0;
  z-index: 999999;
  left: 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  height: 20px;
  svg {
    height: 100%;
  }
`;

const LogoWrapperHeader = styled.a`
  position: absolute;
  left: 0;
  right: 0;
  height: 20px;
  width: fit-content;
  margin: auto;
  svg {
    height: 100%;
  }
`;

const CopyrightMob = styled.div`
  font-family: Montserrat, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: #ffffff;
  opacity: 0.5;
  margin-top: 16px;
`;

const SkipHistory = styled.a`
  width: 100%;
  background: #2f54eb;
  padding: 13px 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
  justify-content: space-between;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: #ffffff;
  }

  p {
    margin: 0;
  }
`;

const ContainImgHamberger = styled.div`
  cursor: pointer;
`;

// @ts-ignore
class Header extends React.PureComponent {

  static propTypes = {
    status: PropTypes.string.isRequired,
    isShowSkipHistory: PropTypes.bool.isRequired,
  };

  // static defaultProps = {
  //   activeLang: "en",
  // };

  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      closeSkipHistory: false,
    };
  }

  onItemClick = (indicator) => () => {
    document.getElementById(indicator).scrollIntoView({ behavior: "smooth" });

    const { isMenuOpen } = this.state;

    if (isMenuOpen) {
      this.setState({
        isMenuOpen: false,
      });
    }
  };

  onHideMenu = () => {
    this.setState({
      isMenuOpen: false,
    });
  };

  onClickHamberger = () => {
    this.setState({
      isMenuOpen: true,
    });
  };

  handleHiddenSkip = (e) => {
    e.stopPropagation();
    this.setState((state) => ({
      ...state,
      closeSkipHistory: true,
    }));
  };

  renderMobileMenu = (lang) => {
    const { isMenuOpen } = this.state;
    const { status } = this.props;

    if (!isMenuOpen) return null;

    return (
      <Menu>
        <CloseButton onClick={this.onHideMenu}>×</CloseButton>

        <MenuItemMob active={status === JOURNEY} style={{ marginTop: "40px" }} onClick={this.onItemClick(JOURNEY)}>
          {lang.the_history}
        </MenuItemMob>
        <MenuItemMob active={status === NEW_VISION} onClick={this.onItemClick(NEW_VISION)}>
          {lang.new_vision}
        </MenuItemMob>
        <MenuItemMob active={status === INITIAL_PLAN} onClick={this.onItemClick(INITIAL_PLAN)}>
          {lang.initial_plan}
        </MenuItemMob>
        <MenuItemMob active={status === FIRST_FAILURE} onClick={this.onItemClick(FIRST_FAILURE)}>
          {lang.first_failure}
        </MenuItemMob>
        <MenuItemMob active={status === MANGA_ROCK} onClick={this.onItemClick(MANGA_ROCK)}>
          {lang.manga_rock}
        </MenuItemMob>
        <MenuItemMob active={status === NEW_APPROACH} onClick={this.onItemClick(NEW_APPROACH)}>
          {lang.new_approach}
        </MenuItemMob>
        <MenuItemMob active={status === THE_CRISIS} onClick={this.onItemClick(THE_CRISIS)}>
          {lang.crisis}
        </MenuItemMob>
        <MenuItemMob active={status === SURVIVAL_MODE} onClick={this.onItemClick(SURVIVAL_MODE)}>
          {lang.survival_mode}
        </MenuItemMob>
        <MenuItemMob active={status === THE_FUTURE} onClick={this.onItemClick(THE_FUTURE)}>
          {lang.future}
        </MenuItemMob>

        <FooterContain>
          <LogoWrapper>
            <LogoInkr />
          </LogoWrapper>
          <CopyrightMob>INKR © 2020</CopyrightMob>
        </FooterContain>
      </Menu>
    );
  };

  render() {
    const { status, isShowSkipHistory } = this.props;
    const { closeSkipHistory } = this.state;
    return (
      <LanguageContext.Consumer>
        {(lang) => (
          <>
            <HeaderDesktop>
              <div className="container" style={{ paddingTop: "30px" }}>
                <Row className="row">
                  <MenuItem title={lang.the_history} active={status === JOURNEY} onClick={this.onItemClick(JOURNEY)}>
                    <TextMenu>{lang.the_history}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.new_vision}
                    active={status === NEW_VISION}
                    onClick={this.onItemClick(NEW_VISION)}
                  >
                    <TextMenu>{lang.new_vision}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.initial_plan}
                    active={status === INITIAL_PLAN}
                    onClick={this.onItemClick(INITIAL_PLAN)}
                  >
                    <TextMenu>{lang.initial_plan}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.first_failure}
                    active={status === FIRST_FAILURE}
                    onClick={this.onItemClick(FIRST_FAILURE)}
                  >
                    <TextMenu>{lang.first_failure}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.manga_rock}
                    active={status === MANGA_ROCK}
                    onClick={this.onItemClick(MANGA_ROCK)}
                  >
                    <TextMenu>{lang.manga_rock}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.new_approach}
                    active={status === NEW_APPROACH}
                    onClick={this.onItemClick(NEW_APPROACH)}
                  >
                    <TextMenu>{lang.new_approach}</TextMenu>
                  </MenuItem>
                  <MenuItem title={lang.crisis} active={status === THE_CRISIS} onClick={this.onItemClick(THE_CRISIS)}>
                    <TextMenu>{lang.crisis}</TextMenu>
                  </MenuItem>
                  <MenuItem
                    title={lang.survival_mode}
                    active={status === SURVIVAL_MODE}
                    onClick={this.onItemClick(SURVIVAL_MODE)}
                  >
                    <TextMenu>{lang.survival_mode}</TextMenu>
                  </MenuItem>
                  <MenuItem title={lang.future} active={status === THE_FUTURE} onClick={this.onItemClick(THE_FUTURE)}>
                    <TextMenu>{lang.future}</TextMenu>
                  </MenuItem>
                </Row>
              </div>
            </HeaderDesktop>

            {/* MOBILE */}
            <HeaderMobile>
              <MobileItem active>
                <ContainImgHamberger aria-hidden="true" onClick={this.onClickHamberger}>
                  <img src={mdlMenu} alt="Humberger" />
                </ContainImgHamberger>
                <LogoWrapperHeader href="#">
                  <LogoInkr color="black" />
                </LogoWrapperHeader>
              </MobileItem>
              {!closeSkipHistory && isShowSkipHistory && (
                <SkipHistory href={`#${END_HISTORY}`}>
                  <p>
                    Read this already? <strong>Skip to the next part</strong>
                  </p>
                  <div onClick={this.handleHiddenSkip} aria-hidden="true">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M9.91069 9L14.5249 3.4998C14.6023 3.4084 14.5373 3.26953 14.4177 3.26953H13.015C12.9324 3.26953 12.8533 3.30645 12.7988 3.36973L8.99311 7.90664L5.18745 3.36973C5.13471 3.30645 5.05561 3.26953 4.97123 3.26953H3.5685C3.44897 3.26953 3.38393 3.4084 3.46127 3.4998L8.07553 9L3.46127 14.5002C3.44395 14.5206 3.43283 14.5455 3.42925 14.572C3.42566 14.5985 3.42976 14.6255 3.44105 14.6497C3.45234 14.674 3.47035 14.6945 3.49294 14.7088C3.51553 14.7231 3.54176 14.7306 3.5685 14.7305H4.97123C5.05385 14.7305 5.13295 14.6936 5.18745 14.6303L8.99311 10.0934L12.7988 14.6303C12.8515 14.6936 12.9306 14.7305 13.015 14.7305H14.4177C14.5373 14.7305 14.6023 14.5916 14.5249 14.5002L9.91069 9Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </SkipHistory>
              )}
              {this.renderMobileMenu(lang)}
            </HeaderMobile>
          </>
        )}
      </LanguageContext.Consumer>
    );
  }

}

export default Header;
