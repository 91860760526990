// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { backgroundEven, clBlack, fontComic, orange7 } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/SurvivalMode";
const NAME_IMG = "survival-mode";
const PATH_IMG_MOB = "/images/SurvivalMode/mobile";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;
const ContainImg1 = styled.div`
  margin: 80px auto 6px;
  max-width: 964px;
  min-height: 547px;
  @media screen and (max-width: 768px) {
    margin: 20px auto 24px;
    min-height: 508px;
  }
`;

const ContainImg3 = styled.div`
  max-width: 900px;
  margin: auto;
  min-height: 444px;
  @media screen and (max-width: 768px) {
    min-height: 464px;
  }
`;
const ContainImg4 = styled.div`
  max-width: 947px;
  margin: 15px 0 80px auto;
  min-height: 435px;
  @media screen and (max-width: 768px) {
    margin: 24px 0 48px auto;
    min-height: 464px;
  }
`;
const ContainImg5 = styled.div`
  max-width: 964px;
  min-height: 139px;
  margin: auto;
  @media screen and (max-width: 768px) {
    min-height: 92px;
  }
`;
const ContainImg6 = styled.div`
  margin: 30px auto 70px;
  max-width: 964px;
  min-height: 1071px;
  @media screen and (max-width: 768px) {
    margin: 16px auto 48px;
    min-height: 505px;
  }
`;
const ContainImg7 = styled.div`
  min-height: 1079px;
  max-width: 964px;
  margin: auto;
  @media screen and (max-width: 768px) {
    min-height: 529px;
  }
`;
const ContainImg8 = styled.div`
  max-width: 964px;
  margin: 80px auto 20px;
  min-height: 257px;
  @media screen and (max-width: 768px) {
    margin: 48px auto 16px;
    min-height: 186px;
  }
`;
const ContainImg9 = styled.div`
  max-width: 964px;
  margin: auto;
  min-height: 823px;
  @media screen and (max-width: 768px) {
    min-height: 354px;
  }
`;
const ContainImg10 = styled.div`
  max-width: 971px;
  margin: 0 auto 60px;
  min-height: 1047px;
  @media screen and (max-width: 768px) {
    margin: 32px auto 48px;
    min-height: 453px;
  }
`;
const ContainImgArrow = styled.div`
  max-width: 145px;
  margin: 12px auto 11px;
  min-height: 154px;
  @media screen and (max-width: 768px) {
    max-width: 80px;
    margin: 16px auto;
    min-height: 85px;
  }
`;
const ContainImgArrow3 = styled.div`
  max-width: 269px;
  margin: 12px auto 31px;
  min-height: 154px;
  @media screen and (max-width: 768px) {
    max-width: 148px;
    margin: 16px auto;
    min-height: 85px;
  }
`;

const ContainImg11 = styled.div`
  max-width: 900px;
  margin: 40px auto 87px;
  min-height: 197px;
  @media screen and (max-width: 768px) {
    margin: 16px auto 48px;
    min-height: 128px;
  }
`;
const ContainImg12 = styled.div`
  max-width: 904px;
  margin: auto;
  min-height: 592px;
  @media screen and (max-width: 768px) {
    margin: 16px auto 48px;
    min-height: 290px;
  }
`;
const ContainImg13 = styled.div`
  max-width: 960px;
  margin: 20px auto 40px;
  min-height: 671px;
  @media screen and (max-width: 768px) {
    margin: 16px auto;
    min-height: 323px;
  }
`;
const ContainImg14 = styled.div`
  max-width: 930px;
  margin: auto;
  min-height: 147px;
  @media screen and (max-width: 768px) {
    min-height: 128px;
  }
`;
const ContainImg15 = styled.div`
  max-width: 930px;
  margin: 15px auto 100px;
  min-height: 720px;
  @media screen and (max-width: 768px) {
    margin: 12px auto 48px;
    min-height: 389px;
  }
`;
const ContainImg16 = styled.div`
  margin: 40px auto 90px;
  min-height: 1346px;
  @media screen and (max-width: 1024px) {
    min-height: 1077px;
  }
  @media screen and (max-width: 768px) {
    margin: 12px auto 48px;
    min-height: 374px;
  }
`;

const Caption = styled.p`
  font-family: ${fontComic};
  max-width: 900px;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  color: ${clBlack};
  margin: auto;
  @media screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const CaptionLaunch1 = styled.p`
  font-family: ${fontComic};
  max-width: 717px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: ${clBlack};
  margin: auto;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }
`;
const CaptionLaunch2 = styled.p`
  font-family: ${fontComic};
  max-width: 800px;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 44px;
  text-align: center;
  color: ${clBlack};
  margin: auto;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
  }
`;

const CaptionHighLight = styled.p`
  font-family: ${fontComic};
  max-width: 831px;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  text-align: center;
  color: ${orange7};
  margin: 33px auto 0;
  @media screen and (max-width: 768px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

const ContainGroupFirst = styled.div`
  max-width: 984px;
  margin: auto;
`;

const SurvivalMode = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.survival_mode_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.survival_mode_des}
        style={{
          maxWidth: "1280px",
          background: backgroundEven,
        }}
      >
        <OurNewVisionContainer>
          <ContainGroupFirst>
            <ContainImg1>
              <LazyLoad {...configLazyLoad}>
                <picture>
                  <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
                </picture>
              </LazyLoad>
            </ContainImg1>

            <ContainImg3>
              <LazyLoad {...configLazyLoad}>
                <picture>
                  <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} media="(max-width: 768px)" />
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
                </picture>
              </LazyLoad>
            </ContainImg3>
            <ContainImg4>
              <LazyLoad {...configLazyLoad}>
                <picture>
                  <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} media="(max-width: 768px)" />
                  <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
                </picture>
              </LazyLoad>
            </ContainImg4>
          </ContainGroupFirst>
          <ContainImg5>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-5.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-5.png`} alt={`${NAME_IMG} 5`} />
              </picture>
            </LazyLoad>
          </ContainImg5>
          <ContainImg6>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-6.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-6.png`} alt={`${NAME_IMG} 6`} />
              </picture>
            </LazyLoad>
          </ContainImg6>
          <ContainImg7>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-7.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-7.png`} alt={`${NAME_IMG} 7`} />
              </picture>
            </LazyLoad>
          </ContainImg7>
          <ContainImg8>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-8.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-8.png`} alt={`${NAME_IMG} 8`} />
              </picture>
            </LazyLoad>
          </ContainImg8>
          <ContainImg9>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-9.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-9.png`} alt={`${NAME_IMG} 9`} />
              </picture>
            </LazyLoad>
          </ContainImg9>
          <ContainImg10>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-10.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-10.png`} alt={`${NAME_IMG} 10`} />
              </picture>
            </LazyLoad>
          </ContainImg10>
          <Caption>{lang.survival_mode_caption_1}</Caption>
          <ContainImgArrow>
            <LazyLoad {...configLazyLoad}>
              <Artwork src={`${PATH_IMG}/${NAME_IMG}-arrow.png`} alt={`${NAME_IMG} arrow`} />
            </LazyLoad>
          </ContainImgArrow>
          <Caption>{lang.survival_mode_caption_2}</Caption>
          <ContainImgArrow3>
            <LazyLoad {...configLazyLoad}>
              <Artwork src={`${PATH_IMG}/${NAME_IMG}-arrow3.png`} alt={`${NAME_IMG} arrow 3`} />
            </LazyLoad>
          </ContainImgArrow3>
          <Caption>{lang.survival_mode_caption_3}</Caption>
          <ContainImg11>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-11.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-11.png`} alt={`${NAME_IMG} 11`} />
              </picture>
            </LazyLoad>
          </ContainImg11>
          <ContainImg12>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-12.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-12.png`} alt={`${NAME_IMG} 12`} />
              </picture>
            </LazyLoad>
          </ContainImg12>
          <ContainImg13>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-13.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-13.png`} alt={`${NAME_IMG} 13`} />
              </picture>
            </LazyLoad>
          </ContainImg13>
          <ContainImg14>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-14.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-14.png`} alt={`${NAME_IMG} 14`} />
              </picture>
            </LazyLoad>
          </ContainImg14>
          <ContainImg15>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-15.png`} media="(max-width: 768px)" />

                <Artwork src={`${PATH_IMG}/${NAME_IMG}-15.png`} alt={`${NAME_IMG} 15`} />
              </picture>
            </LazyLoad>
          </ContainImg15>
          <CaptionLaunch1>{lang.survival_mode_caption_4}</CaptionLaunch1>
          <ContainImg16>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-16.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-16.png`} alt={`${NAME_IMG} 16`} />
              </picture>
            </LazyLoad>
          </ContainImg16>
          <CaptionLaunch2>{lang.survival_mode_caption_5}</CaptionLaunch2>
          <CaptionHighLight>{lang.survival_mode_caption_6}</CaptionHighLight>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default SurvivalMode;
