// @ts-nocheck

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { configLazyLoad } from "constants/config";
import React from "react";
import LazyLoad from "react-lazyload";
// @ts-ignore
import Slider from "react-slick";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
// @ts-ignore
import leftBtn from "./carousel-left-button.svg";
// @ts-ignore
import rightBtn from "./carousel-right-button.svg";
import FUZEL_JA from "./Fuzel_ja.svg";
// @ts-ignore
import FUZEL from "./Fuzel.svg";
// @ts-ignore
import FUZEL_COLLAGE_JA from "./FuzelCollage.ja.svg";
// @ts-ignore
import FUZEL_COLLAGE from "./FuzelCollage.svg";
// @ts-ignore
import KEEPSHOT_JA from "./Keepshot.ja.svg";
// @ts-ignore
import KEEPSHOT from "./Keepshot.svg";
// @ts-ignore
import WIKLY_JA from "./Wikly.ja.svg";
// @ts-ignore
import WIKLY from "./Wikly.svg";
// @ts-ignore
import WOODPOST_JA from "./Woodpost.ja.svg";
// @ts-ignore
import WOODPOST from "./Woodpost.svg";


const arrowStyle = {
  width: 40,
  height: 40,
  zIndex: 100,
  top: "calc(50% - 20px)",
};

const nextArrowStyle = {
  ...arrowStyle,
  right: -5,
};

// @ts-ignore
const PrevArrow = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props} style={arrowStyle}>
    <img
      style={{
        display: "block",
        width: "100%",
      }}
      src={leftBtn}
      alt="Prev Next Button"
    />
  </div>
);

// @ts-ignore
const NextArrow = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <div {...props} style={nextArrowStyle}>
    <img
      style={{
        display: "block",
        width: "100%",
      }}
      src={rightBtn}
      alt="Prev Next Button"
    />
  </div>
);

const Desktop = styled.div`
  margin-bottom: 24px;
  max-width: 600px;

  .slick-next:before,
  .slick-prev:before {
    content: "";
  }

  @media (max-width: 600px) {
    display: none !important;
  }
`;

const ScrollMobile = styled.div`
  display: flex;
  overflow: scroll;
  width: calc(100vw - 15px);
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 40px;

  @media (min-width: 601px) {
    display: none !important;
  }
`;

const ImgWrapperMobile = styled.div`
  margin-left: 10px;
  margin-right: 10px;
`;

const ContainSliderDesktop = styled.div`
  min-height: 406px;
`;

const ContainSliderMobile = styled.div`
  min-height: 406px;
  display: none;
  @media (max-width: 601px) {
    display: block;
  }
`;

const Mobile = React.memo(() => (
  <LanguageContext.Consumer>
    {(lang) => (
      <ScrollMobile>
        <ImgWrapperMobile style={{ marginLeft: 20 }}>
          <img
            style={{ maxHeight: 370 }}
            src={lang.lang === "ja" ? FUZEL_COLLAGE_JA : FUZEL_COLLAGE}
            alt="Fuzel Collage"
          />
        </ImgWrapperMobile>
        <ImgWrapperMobile>
          <img style={{ maxHeight: 370 }} src={lang.lang === "ja" ? WOODPOST_JA : WOODPOST} alt="Woodpost" />
        </ImgWrapperMobile>
        <ImgWrapperMobile>
          <img style={{ maxHeight: 370 }} src={lang.lang === "ja" ? WIKLY_JA : WIKLY} alt="Wikly" />
        </ImgWrapperMobile>
        <ImgWrapperMobile>
          <img style={{ maxHeight: 370 }} src={lang.lang === "ja" ? FUZEL_JA : FUZEL} alt="Fuzel" />
        </ImgWrapperMobile>
        <ImgWrapperMobile style={{ paddingRight: 20 }}>
          <img style={{ maxHeight: 370 }} src={lang.lang === "ja" ? KEEPSHOT_JA : KEEPSHOT} alt="Keepshot" />
        </ImgWrapperMobile>
      </ScrollMobile>
    )}
  </LanguageContext.Consumer>
));

const AppsCarousel = React.memo(() => (
  <LanguageContext.Consumer>
    {(lang) => (
      <>
        <Desktop>
          <ContainSliderDesktop>
            <LazyLoad
              {...{
                ...configLazyLoad,
                offset: 500,
              }}
            >
              <Slider
                infinite
                speed={500}
                slidesToShow={2}
                slidesToScroll={2}
                prevArrow={<PrevArrow />}
                nextArrow={<NextArrow />}
              >
                <div>
                  <img
                    style={{ maxHeight: 400 }}
                    src={lang.lang === "ja" ? FUZEL_COLLAGE_JA : FUZEL_COLLAGE}
                    alt="Fuzel Collage"
                  />
                </div>
                <div>
                  <img style={{ maxHeight: 400 }} src={lang.lang === "ja" ? WOODPOST_JA : WOODPOST} alt="Woodpost" />
                </div>
                <div>
                  <img style={{ maxHeight: 400 }} src={lang.lang === "ja" ? WIKLY_JA : WIKLY} alt="Wikly" />
                </div>
                <div>
                  <img style={{ maxHeight: 400 }} src={lang.lang === "ja" ? FUZEL_JA : FUZEL} alt="Fuzel" />
                </div>
                <div>
                  <img style={{ maxHeight: 400 }} src={lang.lang === "ja" ? KEEPSHOT_JA : KEEPSHOT} alt="Keepshot" />
                </div>
              </Slider>
            </LazyLoad>
          </ContainSliderDesktop>
        </Desktop>

        <ContainSliderMobile>
          <LazyLoad
            {...{
              ...configLazyLoad,
              offset: 500,
            }}
          >
            <Mobile />
          </LazyLoad>
        </ContainSliderMobile>
      </>
    )}
  </LanguageContext.Consumer>
));

export default AppsCarousel;
