// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { clBlack, fontComic } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/DeathOfMangaRock";
const PATH_IMG_MOB = "/images/DeathOfMangaRock/mobile";
const NAME_IMG = "manga-rock";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImg1 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-top: 100px;
  min-height: 635px;
  @media screen and (max-width: 768px) {
    min-height: 300px;
    margin-top: 40px;
  }
`;
const ContainImg2 = styled.div`
  min-height: 450px;
  @media screen and (max-width: 768px) {
    min-height: 262px;
  }
`;
const ContainImg3 = styled.div`
  margin: 70px auto;
  max-width: 770px;

  min-height: 214px;
  @media screen and (max-width: 768px) {
    min-height: 180px;
  }
`;
const ContainImg4 = styled.div`
  max-width: 840px;
  margin: auto;
  width: 100%;
  min-height: 455px;
  @media screen and (max-width: 768px) {
    min-height: 519px;
  }
`;
const ContainImg5 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 80px;
  min-height: 907px;
  @media screen and (max-width: 768px) {
    min-height: 461px;
  }
`;
const ContainImg6 = styled.div`
  max-width: 923px;
  margin: auto;
  min-height: 339px;
  @media screen and (max-width: 768px) {
    min-height: 320px;
  }
`;

const Caption1 = styled.p`
  font-family: ${fontComic};
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: ${clBlack};
  margin: 50px auto;
  max-width: 650px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
    max-width: 350px;
  }
`;
const Caption2 = styled.p`
  font-family: ${fontComic};
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 34px;
  text-align: center;
  color: ${clBlack};
  margin: 50px auto;
  max-width: 650px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 26px;
    max-width: 306px;
  }
`;

const DeathOfMangaRock = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.death_of_manga_rock_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.death_of_manga_rock_des}
        style={{ padding: "0 86px" }}
      >
        <OurNewVisionContainer>
          <ContainImg1>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
              </picture>
            </LazyLoad>
          </ContainImg1>
          <Caption1>{lang.death_of_manga_rock_caption_1}</Caption1>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-2.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-2.png`} alt={`${NAME_IMG} 2`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg3>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
              </picture>
            </LazyLoad>
          </ContainImg3>
          <ContainImg4>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
              </picture>
            </LazyLoad>
          </ContainImg4>
          <Caption2>{lang.death_of_manga_rock_caption_2}</Caption2>
          <ContainImg5>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-5.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-5.png`} alt={`${NAME_IMG} 5`} />
              </picture>
            </LazyLoad>
          </ContainImg5>
          <ContainImg6>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-6.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-6.png`} alt={`${NAME_IMG} 6`} />
              </picture>
            </LazyLoad>
          </ContainImg6>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default DeathOfMangaRock;
