import React from "react";


const IconFacebook = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.0001 0C4.47724 0 0 4.49595 0 10.0419C0 15.0159 3.60522 19.1352 8.33225 19.9329V12.1369H5.91994V9.33139H8.33225V7.26274C8.33225 4.86251 9.79213 3.55453 11.9247 3.55453C12.9461 3.55453 13.8238 3.63095 14.0786 3.66461V6.17349L12.5995 6.17421C11.44 6.17421 11.2165 6.7274 11.2165 7.53946V9.32996H13.9832L13.6223 12.1354H11.2165V20C16.1642 19.3953 20 15.171 20 10.039C20 4.49595 15.5228 0 10.0001 0Z"
      fill="white"
    />
  </svg>
);

export default IconFacebook;
