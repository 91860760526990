// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { backgroundEven, cyan7, fontComic } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/NewApproach";
const NAME_IMG = "new-approach";
const PATH_IMG_MOB = "/images/NewApproach/mobile";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImg1 = styled.div`
  margin-top: 93px;
  margin-bottom: 51px;
  min-height: 913px;
  @media screen and (max-width: 768px) {
    margin-top: 19px;
    margin-bottom: 40px;
    min-height: 459px;
  }
`;
const ContainImg2 = styled.div`
  min-height: 824px;
  @media screen and (max-width: 768px) {
    min-height: 463px;
  }
`;
const ContainImg3 = styled.div`
  margin: 91px auto 31px;
  min-height: 140px;
  @media screen and (max-width: 768px) {
    margin: 40px auto;
    min-height: 112px;
  }
`;
const ContainImg4 = styled.div`
  max-width: 892px;
  min-height: 577px;
  @media screen and (max-width: 768px) {
    min-height: 249px;
  }
`;
const ContainImg5 = styled.div`
  max-width: 875px;
  margin: auto;
  margin-right: 0;
  min-height: 557px;
  @media screen and (max-width: 768px) {
    margin-top: -15px;
    z-index: -1;
    min-height: 254px;
  }
`;
const ContainImg6 = styled.div`
  margin-bottom: 100px;
  min-height: 269px;
  @media screen and (max-width: 768px) {
    margin: 30px auto 0;
    min-height: 198px;
  }
`;
const ContainImg7 = styled.div`
  min-height: 106px;
  @media screen and (max-width: 768px) {
    min-height: 156px;
  }
`;
const ContainImg8 = styled.div`
  max-width: 893px;
  margin: 21px auto 0;

  margin-right: 0;
  min-height: 839px;
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    min-height: 335px;
  }
`;
const ContainImg9 = styled.div`
  margin: -50px auto 0;

  margin-right: 0;
  min-height: 686px;
  @media screen and (max-width: 768px) {
    margin-top: -20px;
    min-height: 276px;
  }
`;
const ContainImg10 = styled.div`
  max-width: 895px;
  margin: 12px auto 39px;
  min-height: 435px;
  @media screen and (max-width: 768px) {
    margin: 30px auto;
    min-height: 200px;
  }
`;
const ContainImg11 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 88px;
  min-height: 572px;
  @media screen and (max-width: 768px) {
    margin-bottom: 52px;
    min-height: 250px;
  }
`;
const ContainImg12 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 89px;
  min-height: 161px;
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
    min-height: 100px;
  }
`;
const ContainImg13 = styled.div`
  max-width: 840px;
  margin: auto;
  min-height: 454px;
  @media screen and (max-width: 768px) {
    min-height: 243px;
  }
`;
const ContainImg14 = styled.div`
  max-width: 840px;
  margin: auto;
  min-height: 573px;
  @media screen and (max-width: 768px) {
    min-height: 286px;
  }
`;
const ContainImg15 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 56px;
  min-height: 478px;
  @media screen and (max-width: 768px) {
    margin-top: -5px;
    margin-bottom: 49px;
    min-height: 265px;
  }
`;
const ContainImg16 = styled.div`
  max-width: 840px;
  margin: auto;
  min-height: 807px;
  @media screen and (max-width: 768px) {
    min-height: 396px;
  }
`;

const ContainImg22 = styled.div`
  max-width: 840px;
  margin: auto;
  min-height: 213px;
  @media screen and (max-width: 768px) {
    margin-top: 11px;
    margin: 20px auto;
    min-height: 152px;
  }
`;
const ContainImg23 = styled.div`
  max-width: 425px;
  margin: auto;
  min-height: 212px;
  @media screen and (max-width: 768px) {
    max-width: 195px;
    min-height: 97px;
  }
`;
const ContainImg24 = styled.div`
  max-width: 960px;
  margin: auto;
  min-height: 878px;
  @media screen and (max-width: 768px) {
    min-height: 739px;
  }
`;
const ContainImg25 = styled.div`
  margin: 60px auto 80px;
  min-height: 627px;
  @media screen and (max-width: 768px) {
    margin: 37px auto 50px;
    min-height: 387px;
  }
`;
const ContainImg26 = styled.div`
  max-width: 940px;
  margin: auto;
  min-height: 223px;
  @media screen and (max-width: 768px) {
    min-height: 188px;
  }
`;

const Caption = styled.p`
  font-family: ${fontComic};
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: ${cyan7};
  margin: 100px auto;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 26px;
    margin: 50px auto;
  }
`;

const GroupTimeLine = styled.div`
  max-width: 758px;
  border-left: 3px dashed rgba(0, 0, 0, 0.87);
`;
const ContainerGroupTimeLine = styled.div`
  max-width: 840px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
`;

const ContainImg17 = styled.div`
  margin: 22px 0 87px;
  max-width: 718px;
  min-height: 582px;
  @media screen and (max-width: 768px) {
    margin: 30px auto 40px;
    min-height: 303px;
  }
`;
const ContainImg18 = styled.div`
  min-height: 719px;
  @media screen and (max-width: 768px) {
    min-height: 405px;
  }
`;
const ContainImg19 = styled.div`
  max-width: 718px;
  margin: 30px 0 12px;
  min-height: 548px;
  @media screen and (max-width: 768px) {
    margin: 50px 0 35px 20px;
    min-height: 262px;
  }
`;
const ContainImg20 = styled.div`
  max-width: 686px;
  margin: auto;
  min-height: 199px;
  @media screen and (max-width: 768px) {
    margin-left: 20px;
    min-height: 112px;
  }
`;
const ContainImg21 = styled.div`
  max-width: 686px;
  margin: 70px auto 65px;
  min-height: 449px;
  @media screen and (max-width: 768px) {
    margin: 32px 0 26px 20px;
    min-height: 228px;
  }
`;

const NewApproach = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.new_approach_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        style={{ background: backgroundEven }}
      >
        <OurNewVisionContainer>
          <ContainImg1>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
              </picture>
            </LazyLoad>
          </ContainImg1>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-2.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-2.png`} alt={`${NAME_IMG} 2`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg3>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
              </picture>
            </LazyLoad>
          </ContainImg3>
          <ContainImg4>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
              </picture>
            </LazyLoad>
          </ContainImg4>
          <ContainImg5>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-5.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-5.png`} alt={`${NAME_IMG} 5`} />
              </picture>
            </LazyLoad>
          </ContainImg5>
          <ContainImg6>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-6.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-6.png`} alt={`${NAME_IMG} 6`} />
              </picture>
            </LazyLoad>
          </ContainImg6>
          <Caption>{lang.new_approach_caption_1}</Caption>
          <ContainImg7>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-7.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-7.png`} alt={`${NAME_IMG} 7`} />
              </picture>
            </LazyLoad>
          </ContainImg7>
          <ContainImg8>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-8.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-8.png`} alt={`${NAME_IMG} 8`} />
              </picture>
            </LazyLoad>
          </ContainImg8>
          <ContainImg9>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-9.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-9.png`} alt={`${NAME_IMG} 9`} />
              </picture>
            </LazyLoad>
          </ContainImg9>
          <ContainImg10>
            <LazyLoad {...configLazyLoad}>
              {/* <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" /> */}
              <Artwork src={`${PATH_IMG}/${NAME_IMG}-10.png`} alt={`${NAME_IMG} 10`} />
              {/* </picture> */}
            </LazyLoad>
          </ContainImg10>
          <ContainImg11>
            <LazyLoad {...configLazyLoad}>
              {/* <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media='(max-width: 768px)' /> */}
              <Artwork src={`${PATH_IMG}/${NAME_IMG}-11.png`} alt={`${NAME_IMG} 11`} />
              {/* </picture> */}
            </LazyLoad>
          </ContainImg11>
          <ContainImg12>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-12.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-12.png`} alt={`${NAME_IMG} 12`} />
              </picture>
            </LazyLoad>
          </ContainImg12>

          <ContainImg13>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-13.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-13.png`} alt={`${NAME_IMG} 13`} />
              </picture>
            </LazyLoad>
          </ContainImg13>
          <ContainImg14>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-14.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-14.png`} alt={`${NAME_IMG} 14`} />
              </picture>
            </LazyLoad>
          </ContainImg14>
          <ContainImg15>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-15.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-15.png`} alt={`${NAME_IMG} 15`} />
              </picture>
            </LazyLoad>
          </ContainImg15>
          <ContainImg16>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-16.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-16.png`} alt={`${NAME_IMG} 16`} />
              </picture>
            </LazyLoad>
          </ContainImg16>

          <ContainerGroupTimeLine>
            <GroupTimeLine>
              <ContainImg17>
                <LazyLoad {...configLazyLoad}>
                  <picture>
                    <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-17.png`} media="(max-width: 768px)" />
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-17.png`} alt={`${NAME_IMG} 17`} />
                  </picture>
                </LazyLoad>
              </ContainImg17>
              <ContainImg18>
                <LazyLoad {...configLazyLoad}>
                  <picture>
                    <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-18.png`} media="(max-width: 768px)" />
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-18.png`} alt={`${NAME_IMG} 18`} />
                  </picture>
                </LazyLoad>
              </ContainImg18>
              <ContainImg19>
                <LazyLoad {...configLazyLoad}>
                  <picture>
                    <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-19.png`} media="(max-width: 768px)" />
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-19.png`} alt={`${NAME_IMG} 19`} />
                  </picture>
                </LazyLoad>
              </ContainImg19>
              <ContainImg20>
                <LazyLoad {...configLazyLoad}>
                  <picture>
                    <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-20.png`} media="(max-width: 768px)" />
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-20.png`} alt={`${NAME_IMG} 20`} />
                  </picture>
                </LazyLoad>
              </ContainImg20>
              <ContainImg21>
                <LazyLoad {...configLazyLoad}>
                  <picture>
                    <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-21.png`} media="(max-width: 768px)" />
                    <Artwork src={`${PATH_IMG}/${NAME_IMG}-21.png`} alt={`${NAME_IMG} 21`} />
                  </picture>
                </LazyLoad>
              </ContainImg21>
            </GroupTimeLine>
          </ContainerGroupTimeLine>

          <ContainImg22>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-22.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-22.png`} alt={`${NAME_IMG} 22`} />
              </picture>
            </LazyLoad>
          </ContainImg22>
          <ContainImg23>
            <LazyLoad {...configLazyLoad}>
              {/* <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-23.png`} media="(max-width: 768px)" /> */}
              <Artwork src={`${PATH_IMG}/${NAME_IMG}-23.png`} alt={`${NAME_IMG} 23`} />
              {/* </picture> */}
            </LazyLoad>
          </ContainImg23>
          <ContainImg24>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-24.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-24.png`} alt={`${NAME_IMG} 24`} />
              </picture>
            </LazyLoad>
          </ContainImg24>
          <ContainImg25>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-25.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-25.png`} alt={`${NAME_IMG} 25`} />
              </picture>
            </LazyLoad>
          </ContainImg25>

          <ContainImg26>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-26.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-26.png`} alt={`${NAME_IMG} 26`} />
              </picture>
            </LazyLoad>
          </ContainImg26>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default NewApproach;
