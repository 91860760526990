// @ts-nocheck
import LogoInkr from "components/Footer/LogoInkr";
import { JOURNEY } from "constants/scrollIndicator";
import React from "react";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
// @ts-ignore
import artworkMobile from "./artwork.mobile.png";
// @ts-ignore
import artwork from "./artwork.png";
// @ts-ignore
import arrowIcon from "./icon-arrow.svg";


const Row = styled.div`
  flex-direction: column;
  align-items: center;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 86px;
  margin-bottom: 93px;

  @media screen and (max-width: 1537px) {
    display: none;
  }
`;

const WrongText = styled.h1`
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  padding-left: 24px;
  padding-right: 24px;
  @media screen and (max-width: 1537px) {
    margin-top: 98px;
  }

  @media screen and (max-width: 768px) {
    font-style: normal;
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    padding: 0;
  }
`;

const Artwork = styled.img`
  width: 100%;
  height: auto;
  margin-top: 58px;
`;

const ArtworkSource = styled.source`
  width: 100%;
  height: auto;
  margin-top: 58px;
`;

const ContainIconArrow = styled.div`
  @-webkit-keyframes goDown {
    from {
      opacity: 0.7;
      -webkit-transform: translate3d(0, -5px, 0);
    }

    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 5px, 0);
    }
  }

  @keyframes goDown {
    from {
      opacity: 0.7;
      transform: translate3d(0, -5px, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 5px, 0);
    }
  }

  -webkit-animation: goDown 0.7s ease-in-out 0.05s infinite alternate;
  animation: goDown 0.7s ease-in-out 0.05s infinite alternate;
  margin: 50px auto;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const IntroSection = React.memo(() => {
  const onItemClick = (indicator) => () => {
    document.getElementById(indicator).scrollIntoView({ behavior: "smooth" });
  };
  return (
    <LanguageContext.Consumer>
      {(lang) => (
        <>
          <div className="container">
            <Row className="row">
              <div className="col-sm-12" style={{ paddingBottom: 40 }}>
                <LogoWrapper>
                  <LogoInkr color="black" />
                </LogoWrapper>
                <WrongText>{lang.intro_wrong_text}</WrongText>
                <picture>
                  <ArtworkSource media="(max-width: 768px)" srcSet={artworkMobile} />
                  <Artwork src={artwork} alt="Intro Artwork" />
                </picture>
              </div>
              <ContainIconArrow onClick={onItemClick(JOURNEY)}>
                <img src={arrowIcon} alt="icon arrow" />
              </ContainIconArrow>
            </Row>
          </div>
        </>
      )}
    </LanguageContext.Consumer>
  );
});

export default IntroSection;
