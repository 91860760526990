// @ts-nocheck
import "./App.css";

import DeathOfMangaRock from "components/DeathOfMangaRock";
import FirstFailure from "components/FirstFailure";
import Footer from "components/Footer";
import Header from "components/Header";
import InDepth from "components/InDepth";
import InitialPlan from "components/InitialPlan";
import NewApproach from "components/NewApproach";
import OurNewVision from "components/OurNewVision";
import SurvivalMode from "components/SurvivalMode";
import TheCrisis from "components/TheCrisis";
import TheFuture from "components/TheFuture";
import {
  END_HISTORY,
  FIRST_FAILURE,
  INITIAL_PLAN,
  JOURNEY,
  MANGA_ROCK,
  NEW_APPROACH,
  NEW_VISION,
  SURVIVAL_MODE,
  THE_CRISIS,
  THE_FUTURE,
} from "constants/scrollIndicator";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";

import IntroSection from "./components/IntroSection";
import OurJourney from "./components/OurJourney";
import langEn from "./constants/lang/en";
import langJa from "./constants/lang/ja";
import LanguageContext from "./LanguageContext";


const Intersection = styled.div`
  background-color: transparent;
  width: 0;
  height: 0;
  opacity: 0;
`;
const ContainSticky = styled.div`
  position: relative;
`;

// @ts-ignore
let setStatusTimeOut = null;

const browserLanguage = window.navigator.userLanguage || window.navigator.language;
const browserLang = browserLanguage.indexOf("ja") >= 0 ? "ja" : "en";

function App() {
  const [headerStatus, setHeaderStatus] = useState(JOURNEY);
  const [isShowSkipHistory, setShowSkipHistory] = useState(false);

  // @ts-ignore
  const onChange = useCallback((changes) => {
    // @ts-ignore
    changes.forEach((change) => {
      if (
        (change.boundingClientRect.top <= 0 && change.target.id === JOURNEY) ||
        (change.boundingClientRect.top >= 0 && change.target.id === END_HISTORY)
      ) {
        setShowSkipHistory(true);
      } else {
        setShowSkipHistory(false);
      }
      if (change.intersectionRatio > 0) {
        // @ts-ignore
        if (setStatusTimeOut) {
          // @ts-ignore
          clearTimeout(setStatusTimeOut);
        }

        setStatusTimeOut = setTimeout(() => {
          setHeaderStatus(change.target.id);
        }, 100);
      }
    });
  }, []);

  useEffect(() => {
    const journey = document.getElementById(JOURNEY);
    const vision = document.getElementById(NEW_VISION);
    const initial = document.getElementById(INITIAL_PLAN);
    const failure = document.getElementById(FIRST_FAILURE);
    const mangaRock = document.getElementById(MANGA_ROCK);
    const approach = document.getElementById(NEW_APPROACH);
    const crisis = document.getElementById(THE_CRISIS);
    const survival = document.getElementById(SURVIVAL_MODE);
    const future = document.getElementById(THE_FUTURE);
    const endHistory = document.getElementById(END_HISTORY);

    const HeaderIndicator = [
      endHistory,
      journey,
      vision,
      initial,
      failure,
      mangaRock,
      approach,
      crisis,
      survival,
      future,
    ];

    if ("IntersectionObserver" in window) {
      const options = {
        root: null, // relative to document viewport
        rootMargin: "0px", // margin around root. Values are similar to css property. Unitless values not allowed
        threshold: 1.0, // visible amount of item shown in relation to root
      };

      const observer = new IntersectionObserver(onChange, options);
      HeaderIndicator.forEach((img) => {
        if (img) {
          observer.observe(img);
        }
      });
    }
  }, [onChange]);

  const [lang, setLang] = useState(
    browserLang === "en" ?
      langEn :
      {
        ...langEn,
        ...langJa,
      },
  );
  const [activeLang, setActiveLang] = useState(browserLang);

  const onChangeLanguage = (_lang) => {
    if (_lang === "ja") {
      setLang({
        ...langEn,
        ...langJa,
      });
      setActiveLang("ja");
    } else {
      setActiveLang("en");
      setLang(langEn);
    }
  };

  console.log();

  return (
    <>
      <LanguageContext.Provider value={lang}>
        <IntroSection />
        <ContainSticky>
          <Header
            status={headerStatus}
            onChangeLanguage={onChangeLanguage}
            activeLang={activeLang}
            isShowSkipHistory={isShowSkipHistory}
            // onClickSkipHistory={onClickSkipHistory}
          />

          <Intersection id={JOURNEY} />
          <OurJourney />

          <Intersection id={NEW_VISION} />
          <OurNewVision />

          <Intersection id={INITIAL_PLAN} />
          <InitialPlan />

          <Intersection id={FIRST_FAILURE} />
          <FirstFailure />

          <Intersection id={MANGA_ROCK} />
          <DeathOfMangaRock />

          <Intersection id={NEW_APPROACH} />
          <NewApproach />

          <Intersection id={THE_CRISIS} />
          <TheCrisis />

          <Intersection id={SURVIVAL_MODE} />
          <SurvivalMode />

          <Intersection id={THE_FUTURE} />
          <TheFuture />
        </ContainSticky>
        <InDepth />

        <Footer />
      </LanguageContext.Provider>
    </>
  );
}

export default App;
