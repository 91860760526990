import { configLazyLoad } from "constants/config";
import React, { CSSProperties } from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import { clBlack, colorPrimary, fontComic } from "../../constants/style";


const Title = styled.h2`
  font-style: normal;
  font-weight: 900;
  font-size: 60px;
  line-height: 60px;

  text-align: center;
  text-transform: uppercase;
  color: ${colorPrimary};
  margin: 40px auto;

  @media screen and (max-width: 768px) {
    font-weight: 900;
    font-size: 30px;
    line-height: 35px;
    margin: 15px auto 20px;
  }
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  height: 85px;
  @media screen and (max-width: 768px) {
    max-width: 65px;
    height: 65px;
    margin: auto;
  }
  img {
    width: 100%;
    height: 100%;
  }
`;

const Highlight = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  color: red;
  font-family: ${fontComic};
  margin-top: 10px;
  @media screen and (max-width: 768px) {
    font-size: 14px;
    line-height: 24px;
  }
`;

interface LayoutProps {
  srcIcon: string;
  title: string;
  des?: string;
  paddingDesMob?: string;
  CompTop?: string;
  highlight?: string;
  children: React.ReactNode;
  style?: CSSProperties;
}

const Layout = ({
  srcIcon,
  title,
  des,
  children,
  highlight,
  CompTop,
  style = { padding: "0" },
  paddingDesMob = "0px",
}: LayoutProps) => {
  const Description = styled.p`
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    text-align: center;
    color: ${clBlack};
    font-family: ${fontComic};
    padding: ${style.padding};
    margin: auto;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 24px;
      padding: ${paddingDesMob};
    }
  `;
  const LayoutContainer = styled.div`
    background: ${style.background || "#fff"};
    margin: auto;
    padding: 130px 0 140px;

    @media screen and (max-width: 900px) {
      padding: 60px 20px;
    }
  `;

  const ContainChild = styled.div`
    max-width: ${style.maxWidth || "964px"};

    display: flex;
    flex-direction: column;
    margin: auto;
    @media screen and (max-width: 900px) {
      padding: 0 15px;
    }
  `;

  return (
    <LayoutContainer>
      <ContainChild>
        {CompTop && CompTop}
        <Icon>
          <LazyLoad {...configLazyLoad}>
            <img src={srcIcon} alt={title} />
          </LazyLoad>
        </Icon>
        <Title>{title}</Title>
        {des && <Description>{des}</Description>}
        {highlight && <Highlight>{highlight}</Highlight>}
        {children}
      </ContainChild>
    </LayoutContainer>
  );
};

export default Layout;
