// @ts-nocheck
import { configLazyLoad } from "constants/config";
import { backgroundEven } from "constants/style";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/FirstFailure";
const PATH_IMG_MOB = "/images/FirstFailure/mobile";
const NAME_IMG = "first-failure";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImg1 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-top: 100px;
  min-height: 439px;
  @media screen and (max-width: 768px) {
    margin-top: 24px;
    min-height: 198px;
  }
`;
const ContainImg2 = styled.div`
  max-width: 840px;
  margin: auto;

  min-height: 432px;
  @media screen and (max-width: 768px) {
    min-height: 200.5px;
  }
`;

const ContainImg5 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 63px;
  min-height: 400px;
  @media screen and (max-width: 768px) {
    margin-bottom: 12px;
    min-height: 217px;
  }
`;

const ContainImg6 = styled.div`
  max-width: 800px;
  margin: auto;

  min-height: 291px;
  @media screen and (max-width: 768px) {
    min-height: 226px;
  }
`;
const ContainImg7 = styled.div`
  max-width: 800px;
  margin: auto;

  min-height: 769px;
  @media screen and (max-width: 768px) {
    min-height: 321px;
  }
`;

const ContainImg8 = styled.div`
  max-width: 243px;
  margin: auto;
  margin-top: 60px;
  min-height: 95px;
  @media screen and (max-width: 768px) {
    max-width: 159px;
    margin-top: 24px;
    min-height: 63px;
  }
`;

const FirstFailure = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.first_failure_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.first_failure_des}
        style={{
          padding: "0 86px",
          background: backgroundEven,
        }}
      >
        <OurNewVisionContainer>
          <ContainImg1>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
              </picture>
            </LazyLoad>
          </ContainImg1>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-2.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-2.png`} alt={`${NAME_IMG} 2`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg5>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-5.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-5.png`} alt={`${NAME_IMG} 5`} />
              </picture>
            </LazyLoad>
          </ContainImg5>
          <ContainImg6>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-6.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-6.png`} alt={`${NAME_IMG} 6`} />
              </picture>
            </LazyLoad>
          </ContainImg6>
          <ContainImg7>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-7.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-7.png`} alt={`${NAME_IMG} 7`} />
              </picture>
            </LazyLoad>
          </ContainImg7>
          <ContainImg8>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-8.png`} media="(max-width: 768px)" />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-8.png`} alt={`${NAME_IMG} 8`} />
              </picture>
            </LazyLoad>
          </ContainImg8>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default FirstFailure;
