/* eslint-disable @typescript-eslint/camelcase */
export default {
  lang: "en",
  home: "Home",
  the_history: "The History",
  new_vision: "The New Vision",
  initial_plan: "The Initial Plan",
  first_failure: "The First Failure",
  manga_rock: "The Death of Manga Rock",
  new_approach: "The New Approach",
  crisis: "The Crisis",
  survival_mode: "Survival Mode",
  future: "The Future",

  intro_wrong_text: "The Story of a Scanlation Aggregator App Attempting to Fix Its Wrongdoings",
  intro_apology: `First of all, there’s no excuse for the negative impact aggregator sites like ourselves have created for the
  comic industry and creators.`,
  intro_apology_2: "For that, we are truly sorry.",

  in_depth_header: "Read our posts for more in-depth stories",
  in_depth_cta: "More blog posts",
  our_journey_2009_1: "Learning how to build iPhone apps",
  our_journey_2009_2: `When the App Store was launched 10 years ago, allowing virtually anyone to distribute their apps to all
  iPhone users, a group of college friends was excited at the prospect of building products for millions of people
  globally.`,
  our_journey_2009_3: "So when it was time to decide what to build, we quickly settled on manga.",
  our_journey_2009_4: `It was very obvious - all of us grew up reading manga, and since we were still reading on scanlations
  sites like onemanga.com at the time - we wanted a way to read our favorite titles on the bus rides to
  school.`,
  our_journey_2009_5: "That’s how we started working on Manga Rock, as a learning project.",
  our_journey_2010_1: "Launching Manga Rock apps",
  our_journey_2010_2: `For the next several months, we learned and learned and were happy with what we have built. To see if
  anyone would actually want to use our products, we put up the first version of Manga Rock on the App
  Store in April 2010.`,
  our_journey_2010_3: "To our surprise, many people downloaded & used the apps. We were thrilled!",
  our_journey_2010_4: `After the first version, we wanted to further our learning, and Apple happened to release the first iPad
  in the same year.`,
  our_journey_2010_5: "So with the same eagerness to learn, we decided to create Manga Rock for the iPad next.",
  our_journey_2011_1: "Forming a company to build “F*cking awesome apps”",
  our_journey_2011_2: `When we first started, it was just 3 friends and our desires to learn & build products. We never had any
  ambitious ideals like creating a company, making money or changing the world.`,
  our_journey_2011_3: `However, after 2 years of learning and making the first Manga Rock apps, we knew that in order to make
  awesome products, we definitely need help from other people. So organically, we grew as a team,
  acquiring more knowledge and experience along the way.`,
  our_journey_2011_4: `So in the following 5 years, we stopped working on Manga Rock, and started experimenting with many ideas
  that we thought was “cool” and we’d love to use ourselves.`,
  our_journey_2011_5: "Here are what we’ve built",
  our_journey_2011_6: `All in all, through our first 6-7 years as a team, with dozens of products, many failures, and millions
  of users later, we learned a lot of different things. All of which has led us to who we are right now.`,
  our_journey_2011_7: "You may know us through Manga Rock, a manga scanlation aggregator.",
  our_journey_2011_8: `But at heart, we are a Design-driven Tech Company, enabling us to deliver impact to the audience, on a
  global scale.`,
  our_journey_2016_1: "Re-focusing on comics",
  our_journey_2016_2: `Up until this point, even though we were not actively developing Manga Rock & just left it on the store,
  the app kept accumulating millions of readers worldwide.`,
  our_journey_2016_3: `However, despite working on many different products, we know that deep down, Manga Rock is still the one
  and only app that we resonate with and come back to the most.`,
  our_journey_2016_4: `And there’s a reason for that: as passionate readers, we truly believe in the potential of comics as a
  storytelling medium.`,
  our_journey_2016_5: `From our belief in & love for comics, and our internal drive to build products that could leave a
  positive impact on a large scale, we knew that we wanted to do more with Manga Rock.`,
  our_journey_2016_6:
    "So we dove deeper & learned more about the comics industry & it hits us how ignorant we have been.",
  our_journey_2016_7_1:
    "It dawned on us that we didn’t have to be pirates to spread our love of comics, and that there was another way to do it if we were brave enough.",
  our_journey_2016_7_2:
    "With everything we learned from building products over the last 10 years, we felt that we were in a ",
  our_journey_2016_7_3: "unique position to tackle piracy, not support it.",

  our_new_vision_header: "THE NEW VISION",
  our_new_vision_des: "To fix our wrongdoings, we decided to build an entirely new platform. ",

  initial_plan_header: "The Initial plan",
  initial_plan_des: "We knew what we wanted. We knew our strengths. We had ideas.",

  first_failure_header: "The first failure",
  first_failure_des: "We started pitching our platform to publishers and creators all over the world.",

  death_of_manga_rock_header: "The death of manga rock",
  death_of_manga_rock_des: "We had only one more card to play.",
  death_of_manga_rock_caption_1: "AND With this one decision, we would lose nearly everything...",
  death_of_manga_rock_caption_2: "With MR, we learned how to build things, we evolved, and became who we are today. ",

  new_approach_header: "The New approach",
  new_approach_caption_1: "But all was not well.",

  the_crisis_header: "THE CRISIS",
  the_crisis_des:
    "Ken, OUR CEO, finished the localization project. Finally, he had a chance to look at INKR Comics, Backstage, and content. That was when he realized",
  the_crisis_highlight: "we were in serious trouble.",
  the_crisis_caption_1:
    "We were too confident when we made all those promises. We understood readers, but the global publishing industry was insanely complex. All our plans kept changing.",
  the_crisis_caption_2:
    "On top of that, we were running out of money. If we kept trying to do everything, we’d die before we delivered.",

  survival_mode_header: "SURVIVAL MODE",
  survival_mode_caption_1: "When we said no to coins, we also said no to the great titles these creators made",
  survival_mode_caption_2:
    "If creators wouldn’t work with us, we wouldn’t be able to bring their content to readers. Their comics would remain inaccessible.",
  survival_mode_caption_3:
    "In the end, we felt that all creators should be able to earn a fair wage for their investment of time and passion.",
  survival_mode_caption_4: "We reorganized and changed our timeline. Our entire company was running to the same place:",
  survival_mode_caption_5:
    "AS THRILLED AS WE ARE TO SEE OUR PRODUCTS IN THE HAND OF USERS, NOW WE HAVE TO FACE A MUCH BIGGER CHALLENGE:",
  survival_mode_caption_6: "WE HAVE TO LEARN HOW TO OPERATE AND GROW THE PLATFORM WE HAVE BUILT.",

  the_future_header: "THE FUTURE",
  the_future_des:
    "In order to run a platform - something we have zero experience in, we have to graduate from being purely builders.",
  the_future_highlight:
    "Difficult as it sounds, this ambitious and challenging future gives each of us the motivation to keep moving forward. ",
  the_future_1:
    " In order to run a platform - something we have zero experience in, we have to graduate from being purely builders.",
  the_future_2:
    " Difficult as it sounds, this ambitious and challenging future gives each of us the motivation to keep moving forward.",
  the_future_3: "But no matter how hard we try, we can’t do it all alone.",
  the_future_4: "The help from fellow comic fans like you also matters to us.",
  the_future_5:
    " By following & spreading our story, you can help us convince more publishers and creators to put their content on INKR Comics.",
  the_future_6:
    "By reading on INKR Comics, you show appreciation and support creators, and you help us improve the platform.",
  the_future_7: "Or if you are as passionate as we are about the cause, why not join the INKR team?",
  the_future_button_join: "Join our team",
  the_future_button_pitch: "See our investor pitch",

  footer_text_1: "Making comics",
  footer_text_2: "universally accessible",
  footer_text_3: "MR Comics Blog",
};
