// @ts-nocheck
import { configLazyLoad } from "constants/config";
import React from "react";
import LazyLoad from "react-lazyload";
import styled from "styled-components";

import LanguageContext from "../../LanguageContext";
import Layout from "../Layout";


const PATH_IMG = "/images/InitialPlan";
const PATH_IMG_MOB = "/images/InitialPlan/mobile";
const NAME_IMG = "initial-plan";

const OurNewVisionContainer = styled.div``;

const Artwork = styled.img`
  width: 100%;
  height: auto;
`;

const ContainImg1 = styled.div`
  max-width: 840px;
  margin: auto;
  margin-bottom: 100px;
  min-height: 651px;
  @media screen and (max-width: 768px) {
    margin-bottom: 48px;
    min-height: 305px;
  }
`;
const ContainImg2 = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 76px;
  min-height: 167px;
  @media screen and (max-width: 768px) {
    margin-bottom: 36px;
    min-height: 112px;
  }
`;
const ContainImg3 = styled.div`
  max-width: 800px;
  margin: auto;
  margin-bottom: 80px;
  min-height: 673px;
  @media screen and (max-width: 768px) {
    margin-bottom: 36px;
    min-height: 297px;
  }
`;
const ContainImg4 = styled.div`
  max-width: 800px;
  margin: auto;
  min-height: 144px;
  @media screen and (max-width: 768px) {
    min-height: 132px;
  }
`;

const InitialPlan = React.memo(() => (
  <LanguageContext>
    {(lang) => (
      <Layout
        title={lang.initial_plan_header}
        srcIcon={`${PATH_IMG}/${NAME_IMG}-icon.svg`}
        des={lang.initial_plan_des}
        style={{ padding: "0 86px" }}
        paddingDesMob="0 50px"
      >
        <OurNewVisionContainer>
          <ContainImg1>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source media="(max-width: 768px)" srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-1.png`} />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-1.png`} alt={`${NAME_IMG} 1`} />
              </picture>
            </LazyLoad>
          </ContainImg1>
          <ContainImg2>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source media="(max-width: 768px)" srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-2.png`} />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-2.png`} alt={`${NAME_IMG} 2`} />
              </picture>
            </LazyLoad>
          </ContainImg2>
          <ContainImg3>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source media="(max-width: 768px)" srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-3.png`} />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-3.png`} alt={`${NAME_IMG} 3`} />
              </picture>
            </LazyLoad>
          </ContainImg3>
          <ContainImg4>
            <LazyLoad {...configLazyLoad}>
              <picture>
                <source media="(max-width: 768px)" srcSet={`${PATH_IMG_MOB}/${NAME_IMG}-4.png`} />
                <Artwork src={`${PATH_IMG}/${NAME_IMG}-4.png`} alt={`${NAME_IMG} 4`} />
              </picture>
            </LazyLoad>
          </ContainImg4>
        </OurNewVisionContainer>
      </Layout>
    )}
  </LanguageContext>
));

export default InitialPlan;
